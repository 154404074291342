import React, { useState } from 'react';

import MatchupFinderComponent from './matchupfinder/MatchupFinder';
import MatchupFinderComponentScrims from './matchupfinder/MatchupFinderScrims';

import MatchupFinderSoloQComponent from './matchupfinder/MatchupFinderSoloQ';

const MasterMatchupFinderComponent = () => {
  document.title = "Matchup Finder";

  const [activeBigTab, setActiveBigTab] = useState('officials');

  const Bigtabs = [
    { title: "Officials", id: 'officials', component: <MatchupFinderComponent /> },
    { title: "Scrims", id: 'scrims', component: <MatchupFinderComponentScrims /> },
    { title: "SoloQ", id: 'soloQ', component: <MatchupFinderSoloQComponent/> },
  ];

  const handleBigTabClick = (tabId) => {
    setActiveBigTab(tabId);
  };

  return (
    <div>
      <div style={{ display: 'flex',  width: "100%", justifyContent: 'space-between'}}>
        {Bigtabs.map((tab) => (
          <button
            key={tab.id}
            style={{
              backgroundColor: activeBigTab === tab.id ? '#5F5B5B' : " var(--sidebar-color)",
              color: 'white',
              border: '1px solid rgb(0, 242, 255)',
              borderBottom: activeBigTab === tab.id  ?  "none": '1px solid rgb(0, 242, 255)',

              padding: '10px 20px',
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              cursor: 'pointer',
              width: `${100 / Bigtabs.length - 1}%`,
            }}
            onClick={() => handleBigTabClick(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div>
        {Bigtabs.find((tab) => tab.id === activeBigTab)?.component}
      </div>
    </div>
  );
};

export default MasterMatchupFinderComponent;
