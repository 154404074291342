import Team from "../../Icons/group.png";
import Home from "../../Icons/home.png";
import Calendar from "../../Icons/calendar.png";
import MatchHistory from "../../Icons/table.png";
import Computer from "../../Icons/computer.png";
import History from "../../Icons/history.png";
import Security from "../../Icons/security.png";
import Gear from "../../Icons/gear.png";
import Ward from "../../Icons/ward.webp";
import champion from "../../Icons/plot.png";
import mapka from "../../Icons/map.png";
import human from "../../Icons/human.png";
import opgg from "../../Icons/opgg.png";
import deeplol from "../../Icons/deeplol.png";
import base from "../../Icons/1x1.png";
import playerPosition from "../../Icons/playerPosition.png";
import Officials from "../../Icons/Officials.png";
import Tierlist from "../../Icons/Tierlist.png";
import Login from "../../Icons/Login.png";
import Matchups from "../../Icons/Matchups.png";
import Player from "../../Icons/Player.png";
import Teams from "../../Icons/Teams.png";
import Drafts from "../../Icons/Drafts.png";
import level1 from "../../Icons/lvl_1.png";
import Scouting from "../../Icons/Scouting.png";
import SoloQ from "../../Icons/SoloQ.png";
import Champions from "../../Icons/Champions.png";
import zebatka from "../../Icons/zebatka.png";
import Other from "../../Icons/Other.png";
import TeamDrafts from "../../Icons/Team_Drafts.png";
import Officials_History from "../../Icons/Officials_History.png";
import Scrims_History from "../../Icons/Scrims_History.png";
import Wards from "../../Icons/Wards.png";
import Scrims from "../../Icons/Scrims.png";
import LOG from "../../Icons/LOG.png";
import yt from "../../Icons/yt.png";


const iconDictionary = {
    yt,
    LOG,
    Scrims,
    Wards,
    Scrims_History,
    Officials_History,
    TeamDrafts,
    Other,
    zebatka,
    Champions,
    SoloQ,
    Scouting,
    level1,
    Drafts,
    Teams,
    Player,
    Matchups,
    Login,
    Tierlist,
    Officials,
    base,
    playerPosition,
    deeplol,
    opgg,
    human,
    mapka,
    Ward,
    Gear,
    Team: Team,
    Home: Home,
    Calendar: Calendar,
    MatchHistory: MatchHistory,
    Computer: Computer,
    History: History,
    Security: Security,
    champion
};

export default  iconDictionary;