import React, { useMemo, useRef, useState, useEffect  } from 'react';
import { championPhotos, championData } from './data/ChampionsIcon';
import roleIcon from './data/Role';
import { TeamIcons } from './data/TeamIcons';

const ChampionBlindCounterComponent = ({ filteredMatches = [], filteredTeam, filteredSide  }) => {
  const [selectedChampions, setSelectedChampions] = useState({});
  const [filteredDrafts, setFilteredDrafts] = useState([]);
  const tablesContainerRef = useRef([]);

  const blueChampionsOrder = [
    "BB1",
    "BB2",
    "BB3",
    "BP1",
    "BP2",
    "BP3",
    "BB4",
    "BB5",
    "BP4",
    "BP5",
  ];
  const redChampionsOrder = [
    "RB1",
    "RB2",
    "RB3",
    "RP1",
    "RP2",
    "RP3",
    "RB4",
    "RB5",
    "RP4",
    "RP5",
  ];

  const roleMap = {
    0: 'Top',
    1: 'Jungle',
    2: 'Mid',
    3: 'ADC',
    4: 'Support',
  };

  const roleData = useMemo(() => {
    const roles = Object.keys(roleMap).reduce((acc, key) => {
      acc[key] = { blind: {}, counter: {} };
      return acc;
    }, {});

    const enemies = Object.keys(roleMap).reduce((acc, role) => {
      acc[role] = {};
      return acc;
    }, {});

    filteredMatches.forEach((match) => {
      const selectedTeam = Array.isArray(filteredTeam) ? filteredTeam[0] : filteredTeam;
      console.log(selectedTeam, filteredSide)
      let applicableSides = [];
      if (selectedTeam) {
        if (match.team1 === selectedTeam && (!filteredSide || filteredSide[0] === 'team1' || filteredSide[0] === 'any')) {
          applicableSides.push('B'); // Blue side
        }
        if (match.team2 === selectedTeam && (!filteredSide || filteredSide[0] === 'team2' || filteredSide[0] === 'any')) {
          applicableSides.push('R'); // Red side
        }
      } else {
        applicableSides = ['B', 'R'];
      }

      applicableSides.forEach((sidePrefix) => {
        const opponentSide = sidePrefix === 'B' ? 'R' : 'B'; // Opposite side

        for (let i = 1; i <= 5; i++) {
          const roleIndex = match[`${sidePrefix}${i}R`];
          const pickType = match[`${sidePrefix}${i}BC`];
          const championId = match[`${sidePrefix}P${i}`];

          if (!enemies[roleIndex][championId]) {
            enemies[roleIndex][championId] = { blind: {}, counter: {} };
          }

          for (let j = 1; j <= 5; j++) {
            if (match[`${opponentSide}${j}R`] === roleIndex) {
              const enemyChampion = match[`${opponentSide}P${j}`];
              const enemyTarget = pickType === 'B' ? 'counter' : 'blind';

              if (!enemies[roleIndex][championId][enemyTarget][enemyChampion]) {
                enemies[roleIndex][championId][enemyTarget][enemyChampion] = { count: 0 };
              }
              enemies[roleIndex][championId][enemyTarget][enemyChampion].count++;
            }
          }

          const target = pickType === 'B' ? 'blind' : 'counter';
          if (!roles[roleIndex][target][championId]) {
            roles[roleIndex][target][championId] = 0;
          }
          roles[roleIndex][target][championId]++;
        }
      });
    });

    return { roles, enemies };
  }, [filteredMatches, filteredTeam, filteredSide]);


  const handleChampionClick = (championId, role, category) => {
    const key = `${championId}-${role}-${category}`;

    setSelectedChampions((prev) => {
      if (prev[key]) {
        setFilteredDrafts([]); // Clear drafts when deselecting
        return {}; // Deselect all champions
      } else {
        const filteredDrafts = filteredMatches.filter((match) => {
          for (let i = 1; i <= 5; i++) {
            if (String(match[`BP${i}`]) === String(championId) || String(match[`RP${i}`]) === String(championId)) {
              const sidePrefix = String(match[`BP${i}`]) === String(championId) ? 'B' : 'R';
              const pickType = match[`${sidePrefix}${i}BC`];
              if ((category === 'blind' && pickType === 'B') || (category === 'counter' && pickType === 'C')) {
                return true;
              }
            }
          }
          return false;
        });

        setFilteredDrafts(filteredDrafts); // Update drafts
        return { [key]: true }; // Set only the newly selected champion
      }
    });
  };




  const handleOutsideClick = (event) => {
    const isOutside = tablesContainerRef.current.every((ref) => ref && !ref.contains(event.target));
    if (isOutside) {
      setSelectedChampions({});
      setFilteredDrafts([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  console.log(filteredMatches, filteredDrafts)

  const renderTable = (data, title, role, category, index) => (
    <div style={{ width: '50%', textAlign: 'center' }}>
      <h3>{title}</h3>
      <table
      ref={(el) => (tablesContainerRef.current[index] = el)}

        style={{
          width: '100%',
          textAlign: 'center',
          borderCollapse: 'collapse',
          marginBottom: '20px',
          color: 'white',
        }}
      >
        <thead>
          <tr>
            <th>Champion</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data)
            .sort((a, b) => b[1] - a[1])
            .map(([championId, count]) => {
              const key = `${championId}-${role}-${category}`;
              return (
                <React.Fragment key={championId}>
                  <tr
                    onClick={() => handleChampionClick(championId, role, category)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <img
                        src={championPhotos[championData[championId]] || 'fallback-image-url'}
                        alt={championId}
                        style={{ width: '20px', marginRight: '5px' }}
                      />
                      {championData[championId]}
                    </td>
                    <td>{count}</td>
                  </tr>
                  {selectedChampions[key] && (
                    <tr>
                      <td colSpan="2">
                        <table
                          style={{
                            width: '80%',
                            textAlign: 'center',
                            borderCollapse: 'collapse',
                            marginLeft: '30px',
                            color: 'white',
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Enemy</th>
                              <th>C</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(
                              roleData.enemies[role]?.[championId]?.[category === 'blind' ? 'counter' : 'blind'] || {}
                            )
                              .sort((a, b) => b[1].count - a[1].count)
                              .map(([enemyChampionId, { count }]) => (
                                <tr key={enemyChampionId}>
                                  <td
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <img
                                      src={
                                        championPhotos[championData[enemyChampionId]] ||
                                        'fallback-image-url'
                                      }
                                      alt={enemyChampionId}
                                      style={{ width: '20px', marginRight: '5px' }}
                                    />
                                    {championData[enemyChampionId]}
                                  </td>
                                  <td>{count}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  {selectedChampions[key] && (
                    <>
                    {filteredDrafts.map((match, index) => (
                      <tr>
                      <td colSpan="2">
                      <table>
                        <thead style={{ textAlign: 'center', backgroundColor: 'white' }}>
                          <tr>
                            <th className="default-cell-img"  style={{backgroundColor: match.winner === 100 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 100 ? 1 : 0}</th>
                            <th className="teamName"  style={{ backgroundColor: "rgb(217, 217, 217)" }}><img src={TeamIcons[match.team1]} alt='ni ma ikonki' className="small-image"/></th>
                            <th className="teamName"  style={{ backgroundColor: "rgb(217, 217, 217)" }}><img src={TeamIcons[match.team2]} alt='ni ma ikonki' className="small-image"/></th>
                            <th className="default-cell-img" style={{backgroundColor: match.winner === 200 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 200 ? 1 : 0}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...Array(10)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                              <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell-img" : "default-cell-img"}>
                                <img
                                  src={championPhotos[championData[match[blueChampionsOrder[rowIndex]]]]}
                                  alt={""}
                                  className="small-image"
                                  />
                              </td>
                              {rowIndex !== 3 && rowIndex !== 4 && rowIndex !== 5 && rowIndex !== 8 && rowIndex !== 9 && (
                                <td style={{backgroundColor:  "rgb(217, 217, 217)"}}>
                                </td>
                              )}
                              {rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? (
                                <>
                                {rowIndex === 8 || rowIndex === 9 ? (
                                  <>
                                  <td className={ String(match['BP'+(rowIndex-4)+'']) === String(key.split("-")[0]) ? "yellow-cell-img" : "blue-cell-img" }>
                                    <img src={roleIcon[match['B'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                                  </td>
                                  <td className={ String(match['RP'+(rowIndex-4)+'']) === String(key.split("-")[0]) ? "yellow-cell-img" :"red-cell-img"}>
                                    <img src={roleIcon[match['R'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                                  </td>
                                  </>
                                ):(
                                  <>
                                    <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? (String(match['BP'+(rowIndex-2)+'']) === String(key.split("-")[0]) ? "yellow-cell-img" : "blue-cell-img") : "default-cell-img"}>
                                      <img src={roleIcon[match['B'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                                    </td>
                                    <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? (String(match['RP'+(rowIndex-2)+'']) === String(key.split("-")[0]) ? "yellow-cell-img" : "red-cell-img") : "default-cell-img"}>
                                      <img src={roleIcon[match['R'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                                    </td>
                                  </>
                                )
                              }
                                </>
                              ):(
                                <>
                                </>
                              )}
                              {rowIndex !== 3 && rowIndex !== 4 && rowIndex !== 5 && rowIndex !== 8 && rowIndex !== 9 && (
                                <td style={{backgroundColor:  "rgb(217, 217, 217)"}}>

                                </td>
                              )}
                              <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell-img" : "default-cell-img"}>
                                <img
                                  src={championPhotos[championData[match[redChampionsOrder[rowIndex]]]]}
                                  alt={""}
                                  className="small-image"
                                  />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </td>
                      </tr>
                    ))}
                    </>
                  )}

                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );


  return (
    <div style={{ color: 'white' }}>
      <div style={{ width: '100%', display: 'flex' }}>
        {Object.keys(roleMap).map((roleIndex, index) => {
          const roleName = roleMap[roleIndex];
          const { blind, counter } = roleData.roles[roleIndex];

          return (
            <div key={roleIndex} style={{ width: '20%', textAlign: 'center' }}>
              <h2>
                <img
                  src={roleIcon[roleIndex]}
                  alt={roleName}
                  style={{ width: '30px', marginRight: '10px', verticalAlign: 'middle' }}
                />
                {roleMap[roleIndex]}
              </h2>
              <div style={{ width: '100%', display: 'flex' }}>
                {renderTable(blind, 'Blind', roleIndex, 'blind', index * 2)}
                {renderTable(counter, 'Counter', roleIndex, 'counter', index * 2 + 1)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};


export default ChampionBlindCounterComponent;
