import React, { useEffect, useState, useRef } from 'react';
import { useSeason } from './SeasonContext';
import mapPhotsDictionary from './data/MapData';
import Slider from 'rc-slider';
import { TeamIcons } from './data/TeamIcons';
import roleIcon from './data/Role';
import { championData, championPhotos } from './data/ChampionsIcon';
import { useParams } from 'react-router-dom';
import axiosInstance from './backend/axiosInstance';

const isPointInPolygon = (x, y, polygon) => {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x,
      yi = polygon[i].y;
    const xj = polygon[j].x,
      yj = polygon[j].y;

    const intersect =
      yi > y !== yj > y &&
      x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

const waveTimes = [ // Wave times in seconds
  927,  // Wave 1: 15:27
  957,  // Wave 2: 15:57
  987,  // Wave 3: 16:27
  1017, // Wave 4: 16:57
  1047, // Wave 5: 17:27
  1077, // Wave 6: 17:57
  1107, // Wave 7: 18:27
  1137, // Wave 8: 18:57
  1167, // Wave 9: 19:27
  1197, // Wave 10: 19:57
];

const mapWidth = 500;
const mapHeight = 500;
const minX = -120;
const minY = -120;
const maxX = 14970;
const maxY = 14980;

const translateCoordinates = (x, y) => {
  const newX = ((x - minX) / (maxX - minX)) * mapWidth;
  const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
  return { newX, newY };
};

const isSoloMidWave = (
  waveTime,
  playerPositionData,
  selectedPlayer,
  selectedPlayerSide,
  innerPolygon,
  outerPolygon
) => {
  const timeRangePositions = playerPositionData.filter(
    (pos) => pos.time >= waveTime && pos.time <= waveTime + 7
  );

  const playerPositions = timeRangePositions.filter(
    (pos) => pos.summonerName === selectedPlayer
  );

  // Check if the player is in the Inner Polygon for at least 1 second
  const isInInnerPolygon = playerPositions.some((playerPosition) => {
    const { x, y } = playerPosition;
    const { newX, newY } = translateCoordinates(x, y);
    return isPointInPolygon(newX, newY, innerPolygon);
  });

  if (!isInInnerPolygon) {
    return false; // Player is not in the Inner Polygon for even 1 second
  }

  const teammatesInOuterPolygon = timeRangePositions.filter((pos) => {
    const { x, y } = pos;
    const { newX, newY } = translateCoordinates(x, y);
    return (
      pos.summonerName !== selectedPlayer &&
      pos.teamName === (selectedPlayerSide === "red" ? "red" : "blue") &&
      isPointInPolygon(newX, newY, outerPolygon)
    );
  });

  return teammatesInOuterPolygon.length === 0;
};


const renderWaveTable = ({
  waveTimes,
  playerPositionData,
  selectedPlayer,
  selectedPlayerSide,
  predefinedPolygonRed,
  predefinedPolygonBlue,
  InnerPolygonRed,
  InnerPolygonBlue,
  setTimeRange,
}) => {
  const innerPolygon =
    selectedPlayerSide === "red" ? InnerPolygonRed : InnerPolygonBlue;
  const outerPolygon =
    selectedPlayerSide === "red" ? predefinedPolygonRed : predefinedPolygonBlue;

  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "20px",
        color: "white",
      }}
    >
      <thead>
        <tr>
          <th style={{ border: "1px solid white", padding: "8px" }}>Wave</th>
          {waveTimes.map((time, index) => (
            <th
              key={index}
              style={{
                border: "1px solid white",
                padding: "8px",
                cursor: "pointer",
              }}
              onClick={() => setTimeRange(time)} // Change timer when clicked
            >
              {Math.floor(time / 60)}:{String(time % 60).padStart(2, "0")}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ border: "1px solid white", padding: "8px" }}>
            Solo Mid Contest
          </td>
          {waveTimes.map((time, index) => {
            const soloMidWave = isSoloMidWave(
              time,
              playerPositionData,
              selectedPlayer,
              selectedPlayerSide,
              innerPolygon,
              outerPolygon
            );

            return (
              <td
                key={index}
                style={{
                  border: "1px solid white",
                  padding: "8px",
                  textAlign: "center",
                  backgroundColor: soloMidWave ? "green" : "red",
                }}
              >
                {soloMidWave ? "Yes" : "No"}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};



function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
  const teamOrderDict = isBlueTeam ? {
      [firstChampion]: "BP1",
      [secondChampion]: "BP2",
      [thirdChampion]: "BP3",
      [fourthChampion]: "BP4",
      [fifthChampion]: "BP5",
  } : {
      [firstChampion]: "RP1",
      [secondChampion]: "RP2",
      [thirdChampion]: "RP3",
      [fourthChampion]: "RP4",
      [fifthChampion]: "RP5",
  };

  return [0, 1, 2, 3, 4].map(index => {
      const championKey = teamOrderDict[index];
      return (
          <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
              <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
              <img
                  src={championPhotos[championData[game[championKey]]]}
                  alt={""}
                  className="small-image"
              />
          </div>
      );
  });
}

const PlayerPositionTestZ10Component = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "Player Position Test";
  const { name: initialName } = useParams(); // Initial name from URL params
  const [name, setName] = useState(initialName); // State for selected name
  const [matchData, setMatchData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPlayerSide, setSelectedPlayerSide] = useState(""); // Stores the side of the selected player

  const [playerData, setPlayerData] = useState([]);
  const [playerPositionData, setPlayerPositionData] = useState([]);

  const [customShapePoints, setCustomShapePoints] = useState([]); // Points for the current custom shape
  const [customShapes, setCustomShapes] = useState([]); // Array of saved shapes

  const [timeRange, setTimeRange] = useState(900);

  const [currentIndex, setCurrentIndex] = useState();

  const mapWidth = 500;
  const mapHeight = 500;
  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;

  const translateCoordinates = (x, y) => {
    const newX = ((x - minX) / (maxX - minX)) * mapWidth;
    const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
    return { newX, newY };
  };

  const InnerPolygonBlue = [
    {x: 243, y: 224},
    {x: 279, y: 252},
    {x: 227, y: 290},
    {x: 202, y: 269},
  ]

  const InnerPolygonRed = [
    {x: 230, y: 252},
    {x: 268, y: 207},
    {x: 292, y: 231},
    {x: 255, y: 279},
  ]

  //red
  const predefinedPolygonRed = [
    { x: 229, y: 254 },
    { x: 164, y: 204 },
    { x: 208, y: 168 },
    { x: 259, y: 201 },
    { x: 298, y: 237 },
    { x: 329, y: 266 },
    { x: 338, y: 292 },
    { x: 297, y: 315 },
  ];
  const predefinedPolygonBlue = [
    {x: 160, y: 206},
    {x: 162, y: 245},
    {x: 227, y: 289},
    {x: 293, y: 321},
    {x: 336, y: 292},
    {x: 195, y: 188},
  ];
  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('officials_for_player/?start=' + start + "&end=" + end + '&player_name=' + encodeURIComponent(name))
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMatchData(sortedMatchData);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }, [currentSeasonTime, name]);

  const handleGameSelect = (index) => {
    setSelectedGame(matchData[index]);
    setDropdownOpen(false); // Close dropdown after selection
    setCurrentIndex(index)
    fetchData(matchData[index].gameId); // Fetch ward data for the selected game
    };

    const goToPreviousGame = () => {
      if (currentIndex > 0) {
          handleGameSelect(currentIndex - 1);
      }
    };

  const goToNextGame = () => {
      if (currentIndex < matchData.length - 1) {
          handleGameSelect(currentIndex + 1);
      }
    };

    const handleMapClick = (e) => {
      const mapRect = e.target.getBoundingClientRect();
      const x = e.clientX - mapRect.left;
      const y = e.clientY - mapRect.top;

      console.log(`Clicked coordinates: x=${x}, y=${y}`); // Log coordinates

      setCustomShapePoints((prevPoints) => {
        const updatedPoints = [...prevPoints, { x, y }];
        console.log("Updated customShapePoints:", updatedPoints); // Log updated state
        return updatedPoints;
      });
      console.log(`updated`); // Log coordinates

    };

    useEffect(() => {
      if (name && selectedGame && playerData.length > 0) {
        const player = playerData.find((p) => p.summonerName === name);
        console.log(playerData, selectedGame)
        if (player.teamName === selectedGame.team1) {
          setSelectedPlayerSide("blue"); // Set the player's side (red/blue)
        }
        else
        {
          setSelectedPlayerSide("red"); // Set the player's side (red/blue)

        }
      }
    }, [name, playerData, selectedGame]);


    const saveCustomShape = () => {
      if (customShapePoints.length > 2) { // Minimum 3 points for a shape
        setCustomShapes((prevShapes) => [...prevShapes, customShapePoints]);
        setCustomShapePoints([]); // Reset current points
      } else {
        alert("A shape requires at least 3 points.");
      }
    };

  const fetchData = (gameId) => {
    axiosInstance.get(`playerposition/?midwave=1&gameId=${gameId}`)
      .then(response => {
        setPlayerPositionData(response.data);
        setTimeRange(0) // Initialize time range to full data
      })
      .catch(error => {
        console.error(error);
      });

      axiosInstance.get(`players/?gameId=${gameId}&info=1`)
      .then(response => {
        setPlayerData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handlePlayerSelect = (event) => {
    setName(event.target.value); // Update name based on selection
  };

  return (
    <div>
      <div className='filters' style={{ textAlign: 'center', marginBottom: '20px', color: "black" }}>
      <div style={{ marginTop: '10px' }}>
          <button
            className='button-top-page'
            onClick={goToPreviousGame}
            disabled={currentIndex === 0}
            style={{ marginRight: '15px', padding: '5px 10px' , width: "120px" }}
          >
            {"<-"}Previous
          </button>
        </div>


            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '700px',
                    height: '67px',
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {selectedGame ?  (
                <div style={{
                    border: '1px solid #ccc',
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    width: '700px',
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}>
                        <div
                            key={selectedGame.gameId}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ccc',
                                padding: "10px"
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{width: "120px"}}>
                                    {selectedGame.leagueName} {selectedGame.patch}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {renderChampions(selectedGame, selectedGame.B1R, selectedGame.B2R, selectedGame.B3R, selectedGame.B4R, selectedGame.B5R, true)}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                        <img className="small-image" src={TeamIcons[selectedGame.team1]} alt={selectedGame.team1} style={{ width: '25px', marginRight: '5px' }} />
                                        {selectedGame.team1} vs {selectedGame.team2}
                                        <img className="small-image" src={TeamIcons[selectedGame.team2]} alt={selectedGame.team2} style={{ width: '25px', marginLeft: '5px' }} />
                                    </div>
                                    {renderChampions(selectedGame, selectedGame.R1R, selectedGame.R2R, selectedGame.R3R, selectedGame.R4R, selectedGame.R5R, false)}
                                </div>
                            </div>
                        </div>
                </div>
            ) : 'Select a Game'}

                {dropdownOpen && (
                    <div
                        style={{
                            border: '1px solid #ccc',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 100,
                            width: '700px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            top: '67px',
                        }}
                    >
                        {matchData.map((game, index) => {
                            return (
                                <div
                                    key={game.gameId}
                                    onClick={() => handleGameSelect(index)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        transition: 'background 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.background = '#f0f0f0'}
                                    onMouseLeave={(e) => e.currentTarget.style.background = 'white'}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ width: '120px', textAlign: 'center' }}>
                                            {game.leagueName} {game.patch}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.B1R, game.B2R, game.B3R, game.B4R, game.B5R, true)}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                                <img className="small-image" src={TeamIcons[game.team1]} alt={`Team ${game.team1}`} style={{ width: '35px', marginRight: '10px' }} />
                                                <span style={{ fontWeight: 'bold' }}>{game.team1} vs {game.team2}</span>
                                                <img className="small-image" src={TeamIcons[game.team2]} alt={`Team ${game.team2}`} style={{ width: '35px', marginLeft: '10px' }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.R1R, game.R2R, game.R3R, game.R4R, game.R5R, false)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div style={{ marginTop: '10px' }}>
                <button
                className='button-top-page'
                onClick={goToNextGame}
                disabled={currentIndex === matchData.length - 1}
                style={{ marginLeft: '15px', padding: '5px 10px', width: "120px" }}
                >
                    Next {"->"}
                </button>
            </div>

      </div>
      <div style={{display: 'flex'}}>
<div>

      <div style={{ width: "500px", position: 'relative', display: 'flex', flexDirection: 'column' }} >
        <img src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift' style={{ width: "500px", height: "500px" }}  onClick={handleMapClick}/>
        {selectedPlayerSide === "red" && (
          <svg
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <polygon
              points={predefinedPolygonRed.map(({ x, y }) => `${x},${y}`).join(" ")}
              style={{
                fill: "rgba(255, 0, 0, 0.3)",
                stroke: "red",
                strokeWidth: 2,
              }}
            />
            <polygon
              points={InnerPolygonRed.map(({ x, y }) => `${x},${y}`).join(" ")}
              style={{
                fill: "rgba(255, 0, 0, 0.3)",
                stroke: "red",
                strokeWidth: 2,
              }}
            />
          </svg>
        )}

        {selectedPlayerSide === "blue" && (
          <svg
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <polygon
              points={predefinedPolygonBlue.map(({ x, y }) => `${x},${y}`).join(" ")}
              style={{
                fill: "rgba(0, 0, 255, 0.3)",
                stroke: "blue",
                strokeWidth: 2,
              }}
            />
            <polygon
              points={InnerPolygonBlue.map(({ x, y }) => `${x},${y}`).join(" ")}
              style={{
                fill: "rgba(0, 0, 255, 0.3)",
                stroke: "blue",
                strokeWidth: 2,
              }}
            />
          </svg>
        )}


        {customShapes.map((shape, index) => (
          <svg key={`custom-shape-${index}`} style={{  pointerEvents: "none", position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <polygon
              points={shape.map(({ x, y }) => `${x},${y}`).join(' ')}
              style={{
                fill: 'rgba(255, 0, 255, 0.3)', // Semi-transparent fill
                stroke: 'purple', // Outline color
                strokeWidth: 2,
              }}
              />
          </svg>
        ))}

        {customShapePoints.length > 0 && (
          <svg style={{ pointerEvents: "none", position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <polygon
              points={customShapePoints.map(({ x, y }) => `${x},${y}`).join(' ')}
              style={{
                fill: 'rgba(0, 0, 255, 0.3)', // Different color for the in-progress shape
                stroke: 'blue',
                strokeWidth: 2,
              }}
              />
          </svg>
        )}

{playerPositionData &&
  playerPositionData
    .filter((player) => player.time === timeRange)
    .map((position, idx) => {
      const { newX, newY } = translateCoordinates(position.x, position.y);

      // Check if the player is in the inner polygon
      const isInInnerPolygon =
        position.teamName === selectedGame.team1
          ? isPointInPolygon(newX, newY, InnerPolygonBlue)
          : isPointInPolygon(newX, newY, InnerPolygonRed);

      // Check if there are no teammates in the outer polygon
      const teammatesInOuterPolygon = playerPositionData.filter((p) => {
        const { newX: px, newY: py } = translateCoordinates(p.x, p.y);
        return (
          p.time === timeRange &&
          p.teamName === position.teamName &&
          (position.teamName === selectedGame.team1
            ? isPointInPolygon(px, py, predefinedPolygonBlue)
            : isPointInPolygon(px, py, predefinedPolygonRed))
        );
      });

      const isAloneInZone =
        isInInnerPolygon && teammatesInOuterPolygon.length === 0;

      const shouldHighlight = isAloneInZone && position.summonerName === name;

      return (
        <div
          key={`player--${idx}-t`}
          style={{
            position: "absolute",
            top: `${newY - 13}px`,
            left: `${newX - 13}px`,
            width: "26px",
            height: "26px",
            backgroundColor: shouldHighlight ? "yellow" : "transparent",
            borderRadius: "50%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: `2px`,
              left: `2px`,
              width: "22px",
              height: "22px",
              backgroundColor: position.side,
              borderRadius: "50%",
            }}
          >
            <img
              src={championPhotos[position.championName]}
              alt=""
              style={{
                width: "20px",
                height: "20px",
                position: "absolute",
                borderRadius: "50%",
                top: "1px",
                left: "1px",
              }}
            />
          </div>
        </div>
      );
    })}



            </div>
          <div style={{width: "500px", display: 'flex', justifyContent: 'center', color: 'white'}}>
            <Slider
              min={15 * 60}
              max={20 * 60}
              step={1} // Adjust the step size as needed
              value={timeRange}
              onChange={(value) => setTimeRange(value)} // Update timeRange with the selected value
              marks={{
                [15 * 60]: '15:00',
                [20 * 60]: '20:00',
              }}
              style={{ width: '80%', margin: '20px auto' }}
            />
             {Math.floor(timeRange / 60)}:{String(timeRange % 60).padStart(2, '0')}
          </div>
        </div>
        <div style={{ color: 'white', width: "100%", display: 'flex', paddingTop: "20px", flexDirection: 'column'  }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: "100%", flexDirection: "column" }}>
            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', width: "100%" }}>
              <label>Player: </label>
              <select onChange={handlePlayerSelect} value={name} defaultValue={initialName}>
                <option key={initialName} value={initialName}>
                    {initialName}
                </option>
                {playerData.filter((player) => player.summonerName != initialName).map(player => (
                  <option key={player.summonerName} value={player.summonerName}>
                    {player.summonerName}
                  </option>
                ))}
              </select>
            </div>
            {renderWaveTable({
              waveTimes,
              playerPositionData,
              selectedPlayer: name,
              selectedPlayerSide,
              predefinedPolygonRed,
              predefinedPolygonBlue,
              InnerPolygonRed,
              InnerPolygonBlue,
              setTimeRange,
            })}
          </div>
        </div>
    </div>
</div>

  );
};

export default PlayerPositionTestZ10Component;
