import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { championData } from "./data/ChampionsIcon"; // Replace with your actual path
import { TeamIcons } from "./data/TeamIcons"; // Replace with your actual path
import axiosInstance from './backend/axiosInstance';
import { useSeason } from './SeasonContext';
import axios from "axios";
const ProviewAddForm = () => {
    const { currentSeason, currentSeasonTime, seasonOptions, setCurrentSeason } = useSeason();
    const [playersName, setPlayersName] = useState([]);
// apikey=eNXGj0WD2bmuSy5dAY7pK7OgK3V3SE
  const [formData, setFormData] = useState({
    gameId: "",
    link: "",
    videoName: "",
    patch: "",
    role: "",
    side: "",
    summonerName: "",
    enemySummonerName: "",
    championName: "",
    enemyChampionName: "",
    teamName: "",
    enemyTeamName: "",
    top1: "",
    top2: "",
    jgl1: "",
    jgl2: "",
    mid1: "",
    mid2: "",
    bot1: "",
    bot2: "",
    sup1: "",
    sup2: "",
    title: "",         // Add this line
    description: "",   // Add this line
    upload_date: "",    // Add this line
    start_time: "",
    end_time: ""
  });


  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance
      .get('unique_players/?start=' + start + "&end=" + end)
      .then(response => {
        setPlayersName(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [currentSeasonTime]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    toast.success("Form submitted successfully!");
    console.log("Submitted data:", formData);
    try {
      const response = await axios.post('https://sanchidataportal.com/api3/api3/process', {
        data: formData
      });
      // Show response message from Flask
      toast.success(response.data.message);
      console.log('Task ID:', response.data.task_id);
    } catch (error) {
      toast.error('Error submitting form');
      console.error(error);
    }
  };


  return (
    <div style={{ color: "white", borderColor: "pink" }}>
      <ToastContainer position="bottom-right" autoClose={3000} />
      <h1>Proview Add Form</h1>
      <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        {/* General Fields */}
        <div >
          <label htmlFor="gameId">Game ID:</label>
          <input
            type="text"
            id="gameId"
            name="gameId"
            value={formData.gameId}
            onChange={handleChange}
            required
          />
        </div>
        <div >
          <label htmlFor="gameId">Link:</label>
          <input
            type="text"
            id="link"
            name="link"
            value={formData.link}
            onChange={handleChange}
            required
          />
        </div>
        <div >
          <label htmlFor="gameId">start_time:</label>
          <input
            type="text"
            id="start_time"
            name="start_time"
            value={formData.start_time}
            onChange={handleChange}
            required
          />
        </div>
        <div >
          <label htmlFor="gameId">end_time:</label>
          <input
            type="text"
            id="end_time"
            name="end_time"
            value={formData.end_time}
            onChange={handleChange}
            required
          />
        </div>
        <div >
          <label htmlFor="gameId">VideoName:</label>
          <input
            type="text"
            id="videoName"
            name="videoName"
            value={formData.videoName}
            onChange={handleChange}
            required
          />
        </div>
        <div >
          <label htmlFor="side">Side:</label>
          <input
            type="text"
            id="side"
            name="side"
            value={formData.side}
            onChange={handleChange}
            required
          />
        </div>
        <div >

          <label htmlFor="patch">Patch:</label>
          <input
            type="text"
            id="patch"
            name="patch"
            value={formData.patch}
            onChange={handleChange}
            required
          />
        </div>
        <div >

          <label htmlFor="role">Role:</label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          />
        </div>

                {/* Additional Fields */}
                <div>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="upload_date">Upload Date:</label>
                    <input
                        type="date"
                        id="upload_date"
                        name="upload_date"
                        value={formData.upload_date}
                        onChange={handleChange}
                        required
                    />
                </div>

        {/* Champion Roles */}
        <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
          <div>
            <h3>Team A</h3>
            <div>
                <label htmlFor="summonerName">Summoner Name:</label>
                <select
                    id="summonerName"
                    name="summonerName"
                    value={formData.summonerName}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select a summoner</option>
                    {playersName.map((player) => (
                    <option key={player} value={player}>
                        {player}
                    </option>
                    ))}
                </select>
            </div>

            <div>
                <label htmlFor="championName">Champion Name:</label>
                <select
                    id="championName"
                    name="championName"
                    value={formData.championName}
                    onChange={handleChange}
                    required
                    >
                  <option value="">Select a champion</option>
                  {Object.entries(championData)
                    .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB)) // Sort by champion name
                    .map(([id, name]) => (
                      <option key={id} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
            </div>

            <div>
                <label htmlFor="teamName">Team:</label>
                <select
                    id="teamName"
                    name="teamName"
                    value={formData.teamName}
                    onChange={handleChange}
                    required
                    >
                    <option value="">Select a team</option>
                    {Object.keys(TeamIcons).map((teamKey) => (
                        <option key={teamKey} value={teamKey}>
                        {teamKey}
                    </option>
                    ))}
                </select>
            </div>
            {["top1", "jgl1", "mid1", "bot1", "sup1"].map((role) => (
              <div key={role}>
                <label htmlFor={role}>{role.toUpperCase()}:</label>
                <select
                  id={role}
                  name={role}
                  value={formData[role]}
                  onChange={handleChange}
                  required
                >
    <option value="">Select a champion</option>
    {Object.entries(championData)
      .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB)) // Sort by champion name
      .map(([id, name]) => (
        <option key={id} value={name}>
          {name}
        </option>
      ))}
                </select>
              </div>
            ))}
          </div>

          <div>
            <h3>Team B</h3>
            <div>

            <label htmlFor="enemySummonerName">Enemy Summoner Name:</label>
            <select
                id="enemySummonerName"
                name="enemySummonerName"
                value={formData.enemySummonerName}
                onChange={handleChange}
                required
                >
                <option value="">Select a summoner</option>
                {playersName.map((player) => (
                    <option key={player} value={player}>
                    {player}
                </option>
                ))}
            </select>
            </div>

            <div>

            <label htmlFor="enemyChampionName">Enemy Champion Name:</label>
            <select
                id="enemyChampionName"
                name="enemyChampionName"
                value={formData.enemyChampionName}
                onChange={handleChange}
                required
                >
    <option value="">Select a champion</option>
    {Object.entries(championData)
      .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB)) // Sort by champion name
      .map(([id, name]) => (
        <option key={id} value={name}>
          {name}
        </option>
      ))}
            </select>
            </div>
            <div>

            <label htmlFor="enemyTeamName">Enemy Team:</label>
            <select
                id="enemyTeamName"
                name="enemyTeamName"
                value={formData.enemyTeamName}
                onChange={handleChange}
                required
                >
                <option value="">Select a team</option>
                {Object.keys(TeamIcons).map((teamKey) => (
                    <option key={teamKey} value={teamKey}>
                    {teamKey}
                </option>
                ))}
            </select>
            </div>
            {["top2", "jgl2", "mid2", "bot2", "sup2"].map((role) => (
              <div key={role}>
                <label htmlFor={role}>{role.toUpperCase()}:</label>
                <select
                  id={role}
                  name={role}
                  value={formData[role]}
                  onChange={handleChange}
                  required
                >
    <option value="">Select a champion</option>
    {Object.entries(championData)
      .sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB)) // Sort by champion name
      .map(([id, name]) => (
        <option key={id} value={name}>
          {name}
        </option>
      ))}
                </select>
              </div>
            ))}
          </div>
        </div>

        <button type="submit" style={{ marginTop: "20px" }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default ProviewAddForm;
