import React, { useEffect, useState } from 'react';
import axiosInstance from '../backend/axiosInstance';
import { useSeason } from '../SeasonContext';
import CustomSelect from '../base/CustomSelect';
import { championData, championPhotos } from '../data/ChampionsIcon'; // Assuming championPhotos has the URLs for champion images
import itemIconDictionary from '../data/ItemIcons';
import summonerSpellDictionary from '../data/SummonerSpellData';
import runeIconDictionary from '../data/RuneIcons';
import LoadingComponent from '../base/LoadingComponent';
import roleIcon from '../data/Role';
import { handleROFLClick2, handleVODClick } from '../base/base';

const getColorBySkillSlot = (skillSlot) => {
  switch (skillSlot) {
    case 1:
      return "#1E90FF"; // Blue for Skill 1
    case 2:
      return "#FF8C00"; // Orange for Skill 2
    case 3:
      return "#8A2BE2"; // Purple for Skill 3
    case 4:
      return "#FF4500"; // Red for Skill 4
    default:
      return "#D3D3D3"; // Gray for anything else
  }
};

const SkillRow = ({ skillName, skillSlot, levelData, championName }) => (
  <div style={{ display: 'flex' }}>
    <div
      style={{
        position: "relative",  // Allows absolute positioning of text inside
        display: "inline-block",  // Ensures the image and text are in a block element
        width: "40px",
        height: "40px",
        fontSize: "12px",  // Adjust font size to fit well on the image
        fontWeight: "bold",
        borderRadius: "5px",
        textAlign: "center",  // Center text horizontally
        color: "white",  // Text color for contrast
      }}
    >
      <img
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "5px",  // Keeps the rounded corners
        }}
        src={`https://cdn.communitydragon.org/latest/champion/${championName}/ability-icon/${skillName.toLowerCase()}`}
        alt={`https://raw.communitydragon.org/pbe/game/assets/characters/${championName.toLowerCase()}/hud/icons2d/${championName.toLowerCase()}_${skillName.toLowerCase()}.png`}
      />
      <div
        style={{
          position: "absolute",  // Overlays the text on top of the image
          top: 0,  // Aligns the text at the top of the image
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",  // Optional: adds a transparent black background for readability
        }}
      >
        {skillName}
      </div>
    </div>

    {levelData.map((level, idx) =>
      level.skillSlot === skillSlot ? (
        <div
          key={idx}
          style={{
            backgroundColor: getColorBySkillSlot(level.skillSlot),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "5px",
          }}
        >
          {idx + 1}
        </div>
      ) : (
        <div key={idx} style={{ width: "40px", height: "40px" }}></div>
      )
    )}
  </div>
);


const MatchupFinderComponentScrims = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "Matchup Finder";

  const [selectedLeague, setSelectedLeague] = useState([]);
  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);

  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [selectedChampion, setSelectedChampion] = useState([]);
  const [filterChampion, setFilterChampion] = useState([]);

  const [selectedChampion2, setSelectedChampion2] = useState([]);
  const [filterChampion2, setFilterChampion2] = useState([]);


  const [visibleStatsIndex, setVisibleStatsIndex] = useState(null);

  const [matchupData, setMatchupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hoveredRune, setHoveredRune] = useState({ championIdx: null, runeIdx: null }); // Track hovered rune
  const [hoveredLevel, setHoveredLevel] = useState({ championIdx: null, levelIdx: null }); // Track hovered rune
  const [hoveredItems, setHoveredItems] = useState({ championIdx: null, itemsIdx: null }); // Track hovered rune

  const [levelData, setLevelData] = useState(null);
  const [groupedItems, setGroupedItems] = useState(null);


  const championOption = Object.entries(championData).map(([id, champion]) => ({
    value: id,
    label: champion,
  })).sort((a, b) => a.label.localeCompare(b.label));

  const handleChampionChange = (selectedOptions) => {
    const championValues = selectedOptions.map((option) => option.value);
    const championIds = championValues.map(Number);
    setFilterChampion(championIds);
    setSelectedChampion(selectedOptions);
  };

  const handleChampionChange2 = (selectedOptions) => {
    const championValues = selectedOptions.map((option) => option.value);
    const championIds = championValues.map(Number);
    setFilterChampion2(championIds);
    setSelectedChampion2(selectedOptions);
  };

  const handleLeagueChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterLeague(leagueValues);
    setSelectedLeague(selectedOptions);
  };

  const handlePatchChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleMatchDetailsClick = (roflLink) => {
    if (roflLink) {
      window.open(roflLink, "_blank"); // Opens the ROFL link in a new tab
    } else {
      alert("No Match Details link available.");
    }
  };

  const groupItemsByTime = (items, timeLimit) => {
    const groups = [];
    let currentGroup = [];

    // Sort items by their time property
    const sortedItems = items.sort((a, b) => a.time - b.time);

    sortedItems.forEach((item, idx) => {

      if (currentGroup.length === 0) {
        currentGroup.push(item);
      } else {
        // Check if the current item's time is within the time limit of the last item in the current group
        const lastItemTime = currentGroup[currentGroup.length - 1].time;

        if (item.time - lastItemTime <= timeLimit) {
          currentGroup.push(item);
        } else {
          groups.push(currentGroup); // Push the current group to the groups array
          currentGroup = [item]; // Start a new group with the current item
        }
      }
    });

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  };

  const fetchMatchups = async () => {
    setLoading(true);
    setError(null);

    try {
      const championNames = selectedChampion.map((champ) => champ.label); // Use label for the name
      const enemyChampionNames = selectedChampion2.map((champ) => champ.label);

      const queryParams = new URLSearchParams();

      championNames.forEach((name) => {
        queryParams.append('champions', name);
      });

      enemyChampionNames.forEach((name) => {
        queryParams.append('enemy_champions', name);
      });

      const response = await axiosInstance.get(`matchupfinderScrims/?${queryParams.toString()}`);

      const matchupData = response.data.matching_games;

      const leagues = [...new Set(matchupData.map(game => game.league_name))];
      const patches = [...new Set(matchupData.map(game => game.patch))];

      setLeagueOption(leagues.map(league => ({ value: league, label: league })));
      setPatchOption(patches.map(patch => ({ value: patch, label: patch })));

      setMatchupData(matchupData);
    } catch (err) {
      setError('Failed to fetch matchups.');
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnterLevel = async (game_id, summoner_name, index, champIdx) => {
    setHoveredLevel({ championIdx: `${index}-${champIdx}` })
    try {
      const response = await axiosInstance.get(`abilities/?gameId=${game_id}&playername=${summoner_name}`, {
      });
      setLevelData(response.data);
    } catch (error) {
      console.error("Error fetching level data:", error);
    }
  };

  const handleMouseEnterItems = async (game_id, summoner_name, index, champIdx) => {
    setHoveredItems({ championIdx: `${index}-${champIdx}` })
    try {
      const response = await axiosInstance.get(`items/?gameId=${game_id}&playername=${summoner_name}`, {
      });
      setGroupedItems(groupItemsByTime(response.data, 40));

    } catch (error) {
      console.error("Error fetching level data:", error);
    }
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        fetchMatchups(); // Fetch matchups when Enter key is pressed
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedChampion, selectedChampion2, selectedLeague, selectedPatch]);

  const toggleStats = (index) => {
    if (visibleStatsIndex === index) {
      setVisibleStatsIndex(null);
    } else {
      setVisibleStatsIndex(index); // Show the stats for this game
    }
  };

  const renderMatchups = () => {
    if (loading) return <LoadingComponent/>;
    if (error) return <p>{error}</p>;
    const filteredMatchupData = matchupData.filter(game => {
      const leagueMatch = filterLeague.length === 0 || filterLeague.includes(game.league_name);
      const patchMatch = filterPatch.length === 0 || filterPatch.includes(game.patch);
      return leagueMatch && patchMatch;
    });
    return (
      <div className="matchup-list" style={{padding: "20px"}}>
        {filteredMatchupData.map((game, index) => (
          <div             style={{
            backgroundColor: 'whitesmoke',
            padding: "10px",
            marginBottom: "20px",
            justifyContent: "space-between",
          }}>
          <div
            key={index}
            style={{
              display: "flex",
              backgroundColor: 'whitesmoke',
              padding: "10px",
              justifyContent: "space-between",
            }}
          >
            {/* League Name */}
            <div style={{ width: "5%", padding: "0 10px" }}>
              <strong>League:</strong>
              <div>{game.league_name}</div>
            </div>

            {/* Patch */}
            <div style={{ width: "5%", padding: "0 10px" }}>
              <strong>Patch:</strong>
              <div>{game.patch}</div>
            </div>

            <div style={{ width: "5%", padding: "0 10px" }}>
              <strong>Side:</strong>
              <div style={{color:game.side === "blue" ? "blue" : "red"}}>{game.side}</div>
            </div>
            {/* Champions */}
            <div style={{ width: "70%", padding: "0 10px", display: "flex", flexDirection: "column", gap: "10px" }}>
              {game.champions.map((champion, champIdx) => (
              <div
                key={champIdx}
                className="champion-details"
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Distribute items evenly
                  gap: "10px",
                  alignItems: "center",
                  width: "100%", // Ensures the div takes full width
                }}
              >
                <strong style={{ flex: 1 }}>
                  {champion.summoner_name.split(" ").length > 1 ? champion.summoner_name.split(" ").slice(1).join(" ") : champion.summoner_name}
                </strong>

                {/* Champion Image */}
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img
                    className="small-image"
                    src={roleIcon[champion.role]}
                    alt={champion.role}
                    style={{ width: "30px", height: "30px" }}
                  />
                  <img
                    className="small-image"
                    src={championPhotos[champion.champion_name]}
                    alt={champion.champion_name}
                    style={{ width: "30px", height: "30px" }}
                  />
                  <span>{champion.champion_name}</span>
                </div>

                {/* Enemy Champion Image */}
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img
                    className="small-image"
                    src={championPhotos[champion.enemy_champion_name]}
                    alt={champion.enemy_champion_name}
                    style={{ width: "30px", height: "30px" }}
                  />
                  {champion.enemy_champion_name}
                </div>

                {/* Summoner Spells */}
                <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img
                    className="small-image"
                    src={summonerSpellDictionary[champion.summoner_spells.summoner_spell_1]}
                    alt={champion.summonerSpell1Name}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <img
                    className="small-image"
                    src={summonerSpellDictionary[champion.summoner_spells.summoner_spell_2]}
                    alt={champion.summonerSpell2Name}
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>

                {/* KDA */}
                <span style={{ flex: 1, textAlign: "center" }}>{champion.kills}/{champion.deaths}/{champion.assists}</span>

                {/* Result */}
                <span style={{ flex: 1, textAlign: "center" }}>{champion.winner === 1 ? "WIN" : "LOSE"}</span>

                <div style={{ flex: 1, textAlign: "center", position: "relative"  }}
                  onMouseEnter={() => handleMouseEnterLevel(game.scrim_id, champion.summoner_name, index, champIdx)}
                  onMouseLeave={() => setHoveredLevel({ championIdx: null })} // Hide on mouse leave
                >{champion.level}
                  {hoveredLevel.championIdx === `${index}-${champIdx}` && levelData && (
                    <div
                      style={{
                        position: "absolute",
                        top: "35px",
                        left: "-200px",
                        gap: "10px",
                        backgroundColor: "white",
                        color: 'black',
                        padding: "10px",
                        borderRadius: "10px",
                        border: "1px solid #ddd",
                        zIndex: 10,
                      }}
                    >
                      {['Q', 'W', 'E', 'R'].map((skillName, idx) => (
                        <SkillRow
                          key={idx}
                          skillName={skillName}
                          skillSlot={idx + 1}
                          levelData={levelData}
                          championName={champion.champion_name}
                        />
                      ))}
                    </div>
                  )}


                </div>


                <div
                  style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}
                  onMouseEnter={() => setHoveredRune({ championIdx: `${index}-${champIdx}` })} // Show rune page on hover
                  onMouseLeave={() => setHoveredRune({ championIdx: null })} // Hide on mouse leave
                >
                  <img
                    className="medium-image"
                    src={runeIconDictionary["rune" + champion.runes.rune0]}
                    alt="rune"
                    style={{ width: "30px", height: "30px" }}
                  />
                  {hoveredRune.championIdx === `${index}-${champIdx}` && (
                    <div
                      style={{
                        position: "absolute",
                        top: "35px",
                        left: "0",
                        display: "flex",
                        gap: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        zIndex: 10,
                        alignItems: "center",
                      }}
                    >
                      {Object.values(champion.runes).map((runeId, idx) => (
                        <img
                          key={idx}
                          src={runeIconDictionary["rune" + runeId]}
                          alt={"rune" + runeId}
                          style={{ width: idx==0 ? "35px" : "25px", height:  idx==0 ? "35px" : "25px" }}
                        />
                      ))}
                    </div>
                  )}
                </div>

                {/* Items */}
                <div
                  style={{ flex: 1, display: "flex", justifyContent: "center", position: "relative" }}
                  onMouseEnter={() => handleMouseEnterItems(game.scrim_id, champion.summoner_name, index, champIdx)}
                  onMouseLeave={() => setHoveredItems({ championIdx: null })} // Hide on mouse leave
                >
                  {Object.values(champion.items).map((item_id, idx) => (
                    <img
                      key={idx}
                      className="small-image-2"
                      src={itemIconDictionary["img" + item_id]}
                      alt={"img" + item_id}
                      style={{ width: "25px", height: "25px" }}
                    />
                  ))}
                  {hoveredItems.championIdx === `${index}-${champIdx}` && groupedItems && (
                    <div
                      style={{
                        position: "absolute",
                        top: "35px",
                        left: "-400px",
                        display: "flex",
                        flexWrap: "wrap", // Allow groups to wrap to the next line
                        gap: "10px", // Space between groups
                        padding: "10px",
                        backgroundColor: "#fff",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        zIndex: 10,
                        alignItems: "flex-start", // Align items to the start
                        maxWidth: "600px", // Set a max width for the tooltip
                        justifyContent: 'center'
                      }}
                    >
                      {groupedItems.map((group, groupIdx) => (
                        <div key={groupIdx} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {group.map((item, idx) => (
                              <div key={idx}>
                                <img
                                  className="small-image-2"
                                  src={itemIconDictionary["img" + item.itemID]}
                                  alt={"img" + item.itemID}
                                  style={{ width: "25px", height: "25px", filter: item.status === "item_sold" ? "grayscale(100%)" : "none" }}
                                />
                              </div>
                            ))}
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            {Math.floor(group[0].time / 60)}min
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

              </div>

              ))}
            </div>

            {/* VOD and ROFL buttons */}
            <div style={{ width: "7%", display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
              <button onClick={() => handleROFLClick2(game.scrim_id, 1)}>ROFL</button> {/* Open ROFL link */}
            </div>
            <div style={{ width: "3%", display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
              <button
                style={{ border: "none", backgroundColor: 'whitesmoke' }}
                onClick={() => toggleStats(index)}
              >
                {visibleStatsIndex === index ?  "↑" : "↓" }
              </button>
            </div>
            <div style={{ width: "7%", display: "flex", flexDirection: "column", justifyContent: "center", gap: "10px" }}>
              <button onClick={() => handleMatchDetailsClick(String('/scrim/' + game.scrim_id))}>Match Details</button> {/* Open ROFL link */}
            </div>
          </div>
          <div>
          {visibleStatsIndex === index && (
          <div style={{ marginTop: "10px", padding: "10px", backgroundColor: "#f9f9f9", borderRadius: "5px" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Champion</th>
                  <th>Enemy</th>
                  <th>K&A@8</th>
                  <th>D@8</th>
                  <th>Gold Diff@8</th>
                  <th>CS Diff@8</th>
                  <th>Exp Diff@14</th>
                  <th>K&A@14</th>
                  <th>D@14</th>
                  <th>Gold Diff@14</th>
                  <th>CS Diff@14</th>
                  <th>Exp Diff@14</th>
                  <th>Jungle %</th>
                </tr>
              </thead>
              <tbody>
              {game.champions.map((champion, champIdx) => (
                <tr>
                  <td>{champion.summoner_name.split(" ")[1]}</td>
                  <td>                 <img
                    className="small-image"
                    src={championPhotos[champion.champion_name]}
                    alt={champion.champion_name}
                    style={{ width: "30px", height: "30px" }}
                  />
                  {champion.champion_name}</td>
                  <td>
                  <img
                    className="small-image"
                    src={championPhotos[champion.enemy_champion_name]}
                    alt={champion.enemy_champion_name}
                    style={{ width: "30px", height: "30px" }}
                  />{champion.enemy_champion_name}</td>
                  <td style={{textAlign: 'center'}}>{champion.kills_and_assists_8}</td>
                  <td style={{textAlign: 'center'}}>{champion.deaths_8}</td>
                  <td style={{textAlign: 'center'}}>{champion.gold_diff_8}</td>
                  <td style={{textAlign: 'center'}}>{champion.cs_diff_8}</td>
                  <td style={{textAlign: 'center'}}>{champion.exp_diff_8}</td>
                  <td style={{textAlign: 'center'}}>{champion.kills_and_assists_14}</td>
                  <td style={{textAlign: 'center'}}>{champion.deaths_14}</td>
                  <td style={{textAlign: 'center'}}>{champion.gold_diff_14}</td>
                  <td style={{textAlign: 'center'}}>{champion.cs_diff_14}</td>
                  <td style={{textAlign: 'center'}}>{champion.exp_diff_14}</td>
                  <td style={{textAlign: 'center'}}>{(champion.jungle_proximity*100).toFixed(0)}%</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
          </div>
          </div>

        ))}
      </div>
    );

  };

  return (
    <div>
      <div className='filters'>
        Champion
        <div className='league-chooser'>
          <CustomSelect options={[{ value: 'any', label: 'Any' }, ...championOption]} selectedOption={selectedChampion} onOptionChange={handleChampionChange} />
        </div>
        Champion
        <div className='league-chooser'>
          <CustomSelect options={[{ value: 'any', label: 'Any' }, ...championOption]} selectedOption={selectedChampion2} onOptionChange={handleChampionChange2} />
        </div>
        League
        <div className='short-chooser'>
          <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
        </div>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>

        <button onClick={fetchMatchups}>Find</button>
      </div>

      <div className="matchup-results">
        {renderMatchups()}
      </div>
    </div>
  );
};

export default MatchupFinderComponentScrims;
