import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Level1Component from './Level1';
import ScoutingComponent from './Scouting';
import TeamComponent from './Team';
import TeamScrimComponent from './TeamScrim';
import { useSeason } from './SeasonContext';
import { TeamIcons } from './data/TeamIcons';
import roleIcon from './data/Role'; // Adjust the path accordingly
import TeamHeatmapComponent from './TeamWard';
import TeamSoloqComponent from './TeamSoloqPage';
import MatchHistoryComponent from './MatchHistory';
import DraftsComponent from './TeamDraft';
import ScrimHistoryComponent from './ScrimHistory';
import axiosInstance from './backend/axiosInstance';
import iconDictionary from './data/Icons';
import TeamObjectivesComponent from './TeamPositionObjective';
import JungleCampComponent from './JungleCamp';

const MasterTeamComponent = () => {
  const { currentSeasonTime } = useSeason();

  const { teamName } = useParams();
  document.title = "Team " + teamName;

  const [activeBigTab, setActiveBigTab] = useState('officials');
  const [activeSubTab, setActiveSubTab] = useState('teamPage');
  const [teamMatesData, setTeamMatesData] = useState(null);

  const Bigtabs = [
    { title: "Officials", id: 'officials', icon:"Officials" },
    { title: "Scrims", id: 'scrims', icon:"Scrims" },
  ];

  const officialsTabs = [
    { title: "Team page", id: 'teamPage', component: <TeamComponent teamName={teamName} />, icon: "Teams" },
    { title: "Scouting", id: 'scouting', component: <ScoutingComponent teamName={teamName} />, icon:"Scouting" },
    { title: "Level 1", id: 'level1', component: <Level1Component teamName={teamName} />, icon:"level1" },
    { title: "JGL", id: 'jglpathing', component: <JungleCampComponent teamName={teamName} />, icon:"level1" },

    { title: "Objectives", id: 'objectives', component: <TeamObjectivesComponent teamName={teamName} />, icon:"level1" },
    { title: "Ward", id: 'heatmap', component: <TeamHeatmapComponent teamName={teamName} gameType="Officials"/>, icon:"Wards" },
    { title: "SoloQ", id: 'soloq', component: <TeamSoloqComponent teamName={teamName} />, icon:"SoloQ" },
    { title: "Match History", id: 'matchhistory', component: <MatchHistoryComponent initialTeamName={teamName} />, icon:"Officials_History" },
    { title: "Team Draft", id: 'teamdraft', component: <DraftsComponent initialTeamName={teamName} />, icon:"TeamDrafts" },

  ];

  const scrimsTabs = [
    { title: "Team page", id: 'teamPage', component: <TeamScrimComponent teamName={teamName} />, icon:"Teams" },
    { title: "Scouting", id: 'scouting', component: <ScoutingComponent teamName={teamName} />, icon:"Scouting" },
    { title: "Ward Heatmap", id: 'heatmap', component: <TeamHeatmapComponent teamName={teamName} gameType="Scrims" />, icon:"Wards" },
    { title: "Match History", id: 'matchhistory', component: <ScrimHistoryComponent initialTeamName={teamName} />, icon:"Scrims_History" },
    { title: "Team Draft", id: 'teamdraft', component: <DraftsComponent initialTeamName={teamName} initialLeagueName={"SCRIMS"} />, icon:"TeamDrafts" },


  ];

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get(`teammates/?name=${teamName}&start=${start}&end=${end}`)
    .then(response => {
      setTeamMatesData(response.data);
    })
    .catch(error => {
      console.error(error);
    });
    }, [currentSeasonTime, teamName]);


  const handleBigTabClick = (tabId) => {
    setActiveBigTab(tabId);
    setActiveSubTab('teamPage');
  };

  const handleSubTabClick = (tabId) => {
    setActiveSubTab(tabId);
  };

  const tabs = activeBigTab === 'officials' ? officialsTabs : scrimsTabs;

  return (
    <div>
      <div style={{ width: "100%", textAlign: "center", color: "white" }}>
        Scrims and Officials
      </div>
      <div style={{display: 'flex', width: "100%", justifyContent: 'center', color: 'black', backgroundColor: "white", marginBottom: "10px", alignItems: "center", textAlign: "center",borderBottom: "2px solid rgb(0, 242, 255)" , borderTop: "2px solid rgb(0, 242, 255)" }}>
        {teamMatesData && (
          <div className="teammates-container">
            <div  style={{alignItems: "center", display: 'flex', justifyContent: 'center'}}  >
              <a
                key={teamName}
                href={`/team/${teamName}`}
                style={{textDecoration: "None"}}
                >
                  <div style={{marginTop: "10px", marginBottom: "10px"}}  className="teammate">
                    <img
                      src={TeamIcons[teamName]}
                      alt={`Role Icon`}
                      className="small-image"
                      />
                    <b className="teammate-name-2" style={{color: 'black'}}>{teamName}</b>
                  </div>
                </a>
              {teamMatesData.map(teammate => (
                <a
                key={teammate.summonerName}
                href={`/player/${encodeURIComponent(teammate.summonerName)}`}
                style={{textDecoration: "None"}}
                >
                  <div style={{marginTop: "10px", marginBottom: "10px"}} className="teammate">
                    <img
                      src={roleIcon[teammate.Role]}
                      alt={`Role ${teammate.Role} Icon`}
                      className="role-icon"
                      />
                    <span className="teammate-name-2" style={{color: 'black'}}>{teammate.summonerName}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      <div style={{ display: 'flex',  width: "100%", justifyContent: 'space-between'}}>
        {Bigtabs.map((tab) => (
          <button
            key={tab.id}
            style={{
              backgroundColor: activeBigTab === tab.id ? '#5F5B5B' : " var(--sidebar-color)",
              color: 'white',
              border: '1px solid rgb(0, 242, 255)',
              borderBottom: activeBigTab === tab.id  ?  "none": '1px solid rgb(0, 242, 255)',

              padding: '10px 20px',
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              cursor: 'pointer',
              width: `${100 / Bigtabs.length - 1}%`,
            }}
            onClick={() => handleBigTabClick(tab.id)}
          >
            <img src={iconDictionary[tab.icon]} alt="" className='small-image'/>
            {tab.title}
          </button>
        ))}
      </div>

      <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', backgroundColor: '#5F5B5B', paddingTop: "10px" }}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            style={{
              backgroundColor: activeSubTab === tab.id ? '#949191' : '#5F5B5B',
              color: 'white',
              border: '1px solid rgb(0, 242, 255)',
              padding: '5px 10px',
              cursor: 'pointer',
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              borderBottom: "none",

              width: `${100 / tabs.length-1}%`,
            }}
            onClick={() => handleSubTabClick(tab.id)}
          >
            <img src={iconDictionary[tab.icon]} alt="" className='small-image'/>
            {tab.title}
          </button>
        ))}
      </div>

      <div>
        {tabs.find((tab) => tab.id === activeSubTab)?.component}
      </div>
    </div>
  );
};

export default MasterTeamComponent;
