import React, { useEffect, useState } from 'react';
import { championPhotos } from './data/ChampionsIcon'; // Assuming this is a mapping of champion names to their respective images
import './SoloqPageComponent.css'; // Import the CSS file
import iconDictionary from './data/Icons';
import axiosInstance from './backend/axiosInstance';
import { getRedGreenCellno0 } from './base/colorUtils';

const WhiteSoloqPageComponent = ({ name, filterPatch, startDate, endDate }) => {
  const [allGameData, setAllGameData] = useState([]);
  const [averageStatsByChampion, setAverageStatsByChampion] = useState({});
  const [playerAccounts, setPlayerAccounts] = useState([]);

  const getPlayerNameWithoutTeamTagLower = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ').toLowerCase(); // Remove the team tag
  };

  const getPlayerNameWithoutTeamTag = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' '); // Remove the team tag
  };

  // Fetch data only when `name` or `filterPatch` changes
  useEffect(() => {
    const playerName = getPlayerNameWithoutTeamTagLower(name);

    axiosInstance
      .get(`soloQ/?playername=${playerName}`)
      .then((response) => {
        const data = response.data.filter((game) =>
          filterPatch.length === 0 || filterPatch.includes(game.patch)
        );
        setAllGameData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axiosInstance
      .get(`accounts/?playername=${playerName}`)
      .then((response) => {
        setPlayerAccounts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching accounts:', error);
      });
  }, [name, filterPatch]);

  // Filter data by date range and compute stats
  useEffect(() => {
    const filteredData = allGameData.filter((game) => {
      const gameDate = new Date(game.date);
      return gameDate >= startDate && gameDate <= endDate;
    });

    if (filteredData.length > 0) {
      const statsByChampion = filteredData.reduce((acc, game) => {
        if (!acc[game.champion_name]) {
          acc[game.champion_name] = {
            games: 0,
            kills: 0,
            deaths: 0,
            assists: 0,
            cs: 0,
            dmg: 0,
            win: 0,
            duration: 0,
          };
        }
        acc[game.champion_name].games += 1;
        acc[game.champion_name].kills += game.kills;
        acc[game.champion_name].deaths += game.deaths;
        acc[game.champion_name].assists += game.assists;
        acc[game.champion_name].cs += game.cs;
        acc[game.champion_name].dmg += game.dmg;
        acc[game.champion_name].win += game.win ? 1 : 0;
        acc[game.champion_name].duration += game.duration;
        return acc;
      }, {});

      const averagesByChampion = Object.keys(statsByChampion).reduce((acc, champion) => {
        const totalStats = statsByChampion[champion];
        acc[champion] = {
          games: totalStats.games,
          kills: (totalStats.kills / totalStats.games).toFixed(1),
          deaths: (totalStats.deaths / totalStats.games).toFixed(1),
          assists: (totalStats.assists / totalStats.games).toFixed(1),
          cs: (totalStats.cs / totalStats.games).toFixed(1),
          csPerMinute: (totalStats.cs / (totalStats.duration / 60)).toFixed(1),
          dmg: (totalStats.dmg / totalStats.games).toFixed(2),
          winRate: ((totalStats.win / totalStats.games) * 100).toFixed(0),
        };
        return acc;
      }, {});

      const sortedAveragesByChampion = Object.fromEntries(
        Object.entries(averagesByChampion).sort(([, a], [, b]) => b.games - a.games)
      );

      setAverageStatsByChampion(sortedAveragesByChampion);
    } else {
      setAverageStatsByChampion({});
    }
  }, [allGameData, startDate, endDate]);

  return (
    <div style={{ backgroundColor: 'white', color: 'black', width: '20%' }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3>{getPlayerNameWithoutTeamTag(name)}</h3>
        </div>
        {Object.keys(averageStatsByChampion).length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {Object.keys(averageStatsByChampion).map((champion) => (
              <div style={{ display: 'flex' }} key={champion}>
                <img
                  src={championPhotos[champion]}
                  alt={champion}
                  className="blue-cell-img"
                />
                <div style={{ width: '100%', display: 'flex' }}>
                  <div style={{ width: '30%' }}>{champion}</div>
                  <div style={{ width: '30%' }}>
                    {averageStatsByChampion[champion].winRate}%
                  </div>
                  <div style={{ width: '30%' }}>
                    {averageStatsByChampion[champion].games} G
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
};

export default WhiteSoloqPageComponent;
