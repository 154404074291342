import React, { useEffect, useState } from 'react';
import axiosInstance from './backend/axiosInstance';
import CustomSelect from './base/CustomSelect';
import Pagination from 'react-bootstrap/Pagination';
import "bootstrap/dist/css/bootstrap.min.css";
import "./MatchHistory.css";
import { championPhotos, championData} from "./data/ChampionsIcon";
import { TeamIcons } from './data/TeamIcons';
import TeamCustomSelect from './TeamCustomSelect';
import { Link } from 'react-router-dom';
import { useSeason } from './SeasonContext';
import roleIcon from './data/Role';
import { handleROFLClick2, handleVODClick } from './base/base';
import  LeagueIcons  from './data/LeagueIcons';

const MatchHistoryComponent = ({ initialTeamName }) => {
  const { currentSeasonTime } = useSeason();
  document.title = "Match History";

  const PAGE_SIZE = 10; // Set the number of matches to display per page
  const [selectedLeague, setSelectedLeague] = useState([]);
  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [teamOption, setTeamOption] = useState([]);

  const [selectedChampion, setSelectedChampion] = useState([]);
  const [filterChampion, setFilterChampion] = useState([]);
  const [championOption, setChampionOption] = useState([]);

  const [matchData, setMatchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [matchesForCurrentPage, setMatchesForCurrentPage] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [hoveredDraft, setHoveredDraft] = useState({ draftIdx: null }); // Track hovered rune

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('parsed_official_games/?start=' + start + "&end=" + end)
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      const filteredMatchData = sortedMatchData.filter((match) => {
        if (["PCL", "EMEAQ"].includes(match.leagueName)) {
          return false;
        }
        if (localStorage.getItem("team") === "FAK" && ["PCL", "PL", "LFL", "TCL", "TCL Cup", "LVP", "UL"].includes(match.leagueName)) {
          return false;
        }
        return true; // Include the match in the filtered data
      });

      setMatchData(filteredMatchData);

      const uniquePatches = [...new Set(filteredMatchData.map((match) => match.patch))];
      const uniqueTeams = getUniqueTeams(filteredMatchData);
      const uniqueLeagues = [...new Set(filteredMatchData.map((match) => match.leagueName))];
      const filteredLeagues = uniqueLeagues.filter((league) => league !== null);
      const formattedLeagueOptions = filteredLeagues.sort().map((league) => ({
        value: league,
        label: league,
      }));

      const formattedPatchOptions = uniquePatches.map((patch) => ({
        value: patch,
        label: patch,
      }));

      const formattedTeamOptions = uniqueTeams.map((team) => ({
        value: team,
        label: team,
      }));


      const formattedChampionOptions = Object.entries(championData).map(([id, champion]) => ({
        value: id,
        label: champion,
      }));
      formattedChampionOptions.sort((a, b) => a.label.localeCompare(b.label));

      setLeagueOption(formattedLeagueOptions);
      setPatchOption(formattedPatchOptions);
      setTeamOption(formattedTeamOptions);
      setChampionOption(formattedChampionOptions);

      if (initialTeamName) {
        const matchedTeam = formattedTeamOptions.find(option => option.value === initialTeamName);
        if (matchedTeam) {
          handleTeamChange([matchedTeam]);
        }
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }, [currentSeasonTime]);

    const getUniqueTeams = (matchData) => {
      const teamsSet = new Set();
      matchData.forEach((match) => {
        teamsSet.add(match.team1);
        teamsSet.add(match.team2);
      });
      return [...teamsSet];
    };

  useEffect(() => {
    // Calculate the total number of pages based on the filtered matchData and PAGE_SIZE
    const filteredMatches = matchData.filter((match) => {
      const isLeagueMatch = filterLeague.length === 0 || filterLeague.includes(match.leagueName);
      const isPatchMatch = filterPatch.length === 0 || filterPatch.includes(match.patch);
      const isTeamMatch = filterTeam.length === 0 || filterTeam.includes(match.team1) || filterTeam.includes(match.team2) ;
      const isNotNull = match.team1 != null;
      // Update the champion filtering logic to use the champion IDs as numbers
      const matchChampions = [
        match.BP1, match.BP2, match.BP3, match.BP4, match.BP5, match.RP1, match.RP2, match.RP3, match.RP4, match.RP5
      ].filter(Boolean); // Remove null or undefined values

      const isChampionMatch =
        filterChampion.length === 0 ||
        filterChampion.every(championId =>
          matchChampions.includes(Number(championId)) // Check if all selected champions are present in the match
        );

      return isLeagueMatch && isPatchMatch && isTeamMatch && isChampionMatch && isNotNull;
    });
    const totalMatches = filteredMatches.length;
    const totalPages = Math.ceil(totalMatches / PAGE_SIZE);
    setTotalPages(totalPages);

    // Ensure the current page stays within the valid range (1 to totalPages) when filters change
    setCurrentPage((prevCurrentPage) => {
      const lastValidPage = Math.min(prevCurrentPage, totalPages);
      return lastValidPage >= 1 ? lastValidPage : 1;
    });

    // Update the matches to be displayed for the current page
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalMatches); // Ensure endIndex doesn't exceed totalMatches
    const matchesForCurrentPage = filteredMatches.slice(startIndex, endIndex);
    setMatchesForCurrentPage(matchesForCurrentPage);

    const uniquePatchesForLeague = [...new Set(filteredMatches.map((match) => match.patch))];
    const uniqueTeamsForLeague = getUniqueTeams(filteredMatches);

    // Update the options for the "Patch" and "Team" filters
    const formattedPatchOptions = uniquePatchesForLeague.sort().map((patch) => ({
      value: patch,
      label: patch,
    }));

    const formattedTeamOptions = uniqueTeamsForLeague
    .sort((a, b) => a.localeCompare(b)) // Sort the team names alphabetically
    .map((team) => ({
      value: team,
      label: team,
    }));
    setPatchOption(formattedPatchOptions);
    setTeamOption(formattedTeamOptions);

}, [currentPage, matchData, filterLeague, filterPatch, filterTeam, filterChampion, PAGE_SIZE]);

  const handleLeagueChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterLeague(leagueValues);
    setSelectedLeague(selectedOptions);
  };

  const handleTeamChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterTeam(leagueValues);
    setSelectedTeam(selectedOptions);
  };

  const handlePatchChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleChampionChange = (selectedOptions) => {
    setMatchesForCurrentPage([]);
    const championValues = selectedOptions.map((option) => option.value);
    const championIds = championValues.map(Number);
    setFilterChampion(championIds);
    setSelectedChampion(selectedOptions);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber === currentPage) { // Check if the clicked page is the current page
      return; // Do nothing if the current page is clicked
    }
    setMatchesForCurrentPage([]);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const ellipsis = <Pagination.Ellipsis />;

    paginationItems.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
    );

    if (totalPages <= 5) {
      // If total pages are less than or equal to 5, display all pages
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Display the first 2 pages and the last 2 pages
      for (let i = 1; i <= 2; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }

      // Display the current page and the last page, and ellipsis in between if needed
      if (currentPage - 2 > 2) {
        paginationItems.push(ellipsis);
      }
      for (let i = Math.max(currentPage - 2, 3); i <= Math.min(currentPage + 2, totalPages - 2); i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }
      if (currentPage + 2 < totalPages - 1) {
        paginationItems.push(ellipsis);
      }

      for (let i = totalPages - 1; i <= totalPages; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
            {i}
          </Pagination.Item>
        );
      }
    }

    // Always display the next page and last page if needed
    paginationItems.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
    );

    return paginationItems;
  };

  const handleClickMap = (gameId) => {
    window.open(`/map/${gameId}`, '_blank');
  };

  const handleClickMatch = (gameId) => {
    window.open(`/match/${gameId}`, '_blank');
  };

  const blueChampionsOrder = [
    "BB1",
    "BB2",
    "BB3",
    "BP1",
    "BP2",
    "BP3",
    "BB4",
    "BB5",
    "BP4",
    "BP5",
  ];
  const redChampionsOrder = [
    "RB1",
    "RB2",
    "RB3",
    "RP1",
    "RP2",
    "RP3",
    "RB4",
    "RB5",
    "RP4",
    "RP5",
  ];


  return (
    <div>
      <div className='filters'>
        League
        <div className='short-chooser'>
          <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
        </div>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
        Team
        <div className='short-chooser'>
          <TeamCustomSelect options={teamOption} selectedOption={selectedTeam} onOptionChange={handleTeamChange} />
        </div>
        Champion
        <div className='short-chooser'>
          <CustomSelect options={championOption} selectedOption={selectedChampion} onOptionChange={handleChampionChange} />
        </div>
      </div>
      <div className='main-content'>
        <table style={{ border: "none",  width: "100%"}}>
          <thead style={{ backgroundColor: 'white'}}>
            <tr>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}></th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>League</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Blue</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Red</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Winner</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Patch</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Blue</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Red</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Link</th>
              <th style={{textAlign: "center", borderBottom: "4px solid black"}}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {matchesForCurrentPage.map((match, index) => {
              const blueTeamOrderDict = {
                [match.B1R]: "BP1",
                [match.B2R]: "BP2",
                [match.B3R]: "BP3",
                [match.B4R]: "BP4",
                [match.B5R]: "BP5",
              };
              const redTeamOrderDict = {
                [match.R1R]: "RP1",
                [match.R2R]: "RP2",
                [match.R3R]: "RP3",
                [match.R4R]: "RP4",
                [match.R5R]: "RP5",
              };
              const winnerTeam = match.winner === 100 ? match.team1 : match.team2;

              return (
                <tr key={match.gameId} className='match-history-row'>
                  <td style={{textAlign: "center"}}>{index + 1 + (currentPage - 1) * PAGE_SIZE}</td>
                  <td style={{textAlign: "center"}}>
                    <Link to={`/match/${match.gameId}`} style={{textDecoration: "none", color: 'inherit'}}>
                      <img src={LeagueIcons[match.leagueName]} style={{marginRight: "5px" }} alt='' className="small-image"/>{match.leagueName}
                    </Link>
                  </td>
                  <td style={{textAlign: "center", fontWeight: "bold"}}>
                    <Link to={`/team/${match.team1}`} style={{textDecoration: "none", color: 'inherit'}}>
                      <img src={TeamIcons[match.team1]} alt='' className="small-image"/> {match.team1}
                    </Link>
                  </td>
                  <td style={{textAlign: "center", fontWeight: "bold"}}>
                    <Link to={`/team/${match.team2}`} style={{textDecoration: "none", color: 'inherit'}}>
                      <img src={TeamIcons[match.team2]} alt='' className="small-image"/> {match.team2}
                    </Link>
                  </td>
                  <td style={{textAlign: "center", fontWeight: "bold"}}>
                    <Link to={`/team/${winnerTeam}`} style={{textDecoration: "none", color: 'inherit'}}>
                      <img src={TeamIcons[winnerTeam]} alt='' className="small-image"/> {winnerTeam}
                    </Link>
                  </td>
                  <td style={{textAlign: "center"}}>{match.patch}</td>
                  <td style={{textAlign: "center"}}>
                    <div
                      onMouseEnter={(e) => {
                        const rowRect = e.currentTarget.getBoundingClientRect();
                        const unfoldDirection = rowRect.top > window.innerHeight / 2 - 20? 'up' : 'down';
                        setHoveredDraft({ draftIdx: `${index}-${match.gameId}`, unfoldDirection });
                      }}
                      onMouseLeave={() => setHoveredDraft({ draftIdx: null })} // Hide on mouse leave
                      style={{textAlign: "center", display: "flex" , justifyContent: 'center'}}>
                    {Object.keys(blueTeamOrderDict).map((blueIndex, index) => (
                      <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                        <img src={roleIcon[index]}   alt={""} style={{width: "15px"}}/>
                        <img key={blueIndex} src={championPhotos[championData[match[blueTeamOrderDict[blueIndex]]]]} alt="" className="small-image"/>
                      </div>
                    ))}
                    </div>
                  </td>
                  <td style={{textAlign: "center"}}>
                    <div
                      onMouseEnter={(e) => {
                        const rowRect = e.currentTarget.getBoundingClientRect();
                        const unfoldDirection = rowRect.top > window.innerHeight / 2 - 20? 'up' : 'down';
                        setHoveredDraft({ draftIdx: `${index}-${match.gameId}`, unfoldDirection });
                      }}
                      onMouseLeave={() => setHoveredDraft({ draftIdx: null })} // Hide on mouse leave
                     style={{textAlign: "center", display: "flex" , justifyContent: 'center', position: "relative"}}>
                    {Object.keys(redTeamOrderDict).map((blueIndex, index) => (
                      <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                        <img src={roleIcon[index]}   alt={""} style={{width: "15px"}}/>
                        <img key={blueIndex} src={championPhotos[championData[match[redTeamOrderDict[blueIndex]]]]} alt="" className="small-image"/>
                      </div>
                    ))}
                    {hoveredDraft.draftIdx === `${index}-${match.gameId}` && (
                      <div
                      style={{
                        position: "absolute",
                        top: hoveredDraft.unfoldDirection === 'up' ? "-350px" : "35px", // Adjust based on direction
                        left: "-100px",
                        gap: "10px",
                        backgroundColor: "white",
                        color: 'black',
                        padding: "5px",
                        borderRadius: "10px",
                        border: "1px solid #ddd",
                        zIndex: 10,
                      }}
                      >
                        <div className='match-draft-container-2' style={{width: "100%"}}>
                          <table>
                            <thead style={{ textAlign: 'center', backgroundColor: 'white' }}>
                              <tr>
                                <th className="default-cell-img"  style={{backgroundColor: match.winner === 100 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 100 ? 1 : 0}</th>
                                <th className="teamName"  style={{ backgroundColor: "rgb(217, 217, 217)" }} > <img src={TeamIcons[match.team1]} alt='ni ma ikonki' className="small-image"/></th>
                                <th className='default-doublecell-img'  colSpan={2}>{match.patch}</th>
                                <th className="teamName"  style={{ backgroundColor:  "rgb(217, 217, 217)" }}><img src={TeamIcons[match.team2]} alt='ni ma ikonki' className="small-image"/></th>
                                <th className="default-cell-img" style={{backgroundColor: match.winner === 200 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 200 ? 1 : 0}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[...Array(10)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell-img" : "default-cell-img"}>
                                    <img
                                      src={championPhotos[championData[match[blueChampionsOrder[rowIndex]]]]}
                                      alt={""}
                                      className="small-image"
                                    />
                                  </td>
                                  <td style={{borderBottom: rowIndex === 3 ? '2px solid black' : 'none'}} colSpan={rowIndex === 0 || rowIndex === 1 || rowIndex === 2 || rowIndex === 6 || rowIndex === 7 ? 2 : 1} className={((rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell" : "default-cell"))}>
                                    {championData[match[blueChampionsOrder[rowIndex]]]}
                                  </td>
                                  {rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? (
                                    <>
                                    {rowIndex === 8 || rowIndex === 9 ? (
                                      <>
                                      <td className={"blue-cell-img"}>
                                        <img src={roleIcon[match['B'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                                      </td>
                                      <td className={"red-cell-img"}>
                                        <img src={roleIcon[match['R'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                                      </td>
                                      </>
                                    ):(
                                      <>
                                        <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "blue-cell-img" : "default-cell-img"}>
                                          <img src={roleIcon[match['B'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                                        </td>
                                        <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "red-cell-img" : "default-cell-img"}>
                                          <img src={roleIcon[match['R'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                                        </td>
                                      </>
                                    )
                                  }
                                    <td colSpan={1} style={{ borderBottom: rowIndex === 4 || rowIndex === 8 ? '2px solid black' : 'none' }}  className={ ( rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell")}>
                                      {championData[match[redChampionsOrder[rowIndex]]]}
                                    </td>
                                    </>
                                  ):(
                                    <>
                                    <td  colSpan={2} className={ (rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell")}>
                                      {championData[match[redChampionsOrder[rowIndex]]]}
                                    </td>
                                    </>
                                  )}
                                  <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell-img" : "default-cell-img"}>
                                    <img
                                      src={championPhotos[championData[match[redChampionsOrder[rowIndex]]]]}
                                      alt={""}
                                      className="small-image"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    </div>
                  </td>
                  <td style={{textAlign: "center"}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: "5px", padding: "5px", fontSize: '10px'}}>
                      {match.Vod && <button className='button-match-history' onClick={() => handleVODClick(match.Vod)}>VOD</button>}
                      <button className='button-match-history' onClick={() => handleROFLClick2(match.gameId, 1)}>ROFL</button>
                    </div>
                  </td>
                  <td style={{textAlign: "center"}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: "5px", padding: "5px", fontSize: '10px'}}>
                      <button className='button-match-history' onClick={() => handleClickMap(match.gameId)}>Map</button>
                      <button className='button-match-history' onClick={() => handleClickMatch(match.gameId)}>Details</button>
                    </div>

                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='main-pagination'>
          <Pagination style={{ display: 'flex', justifyContent: 'center' }}>
            {renderPaginationItems()}
          </Pagination>
        </div>
      </div>

    </div>
  );
};

export default MatchHistoryComponent;
