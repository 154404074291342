import React, { useEffect, useState } from 'react';
import CustomDateInput from './base/CustomInputCalendar';
import { championPhotos } from './data/ChampionsIcon';
import axiosInstance from './backend/axiosInstance';
const SoloQReportComponent = ({ data }) => {
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    const lastWeekStart = new Date();
    lastWeekStart.setDate(now.getDate() - 7);
    return lastWeekStart;
  });
  const [endDate, setEndDate] = useState(new Date());

  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);

  const [currentStats, setCurrentStats] = useState({});
  const [previousStats, setPreviousStats] = useState({});
  const [championStats, setChampionStats] = useState([]);
  const [selectedChampion, setSelectedChampion] = useState(null);
  const [proStats, setProStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const handleFetchProStats = async (champion) => {
    if (selectedChampion === champion) {
      setSelectedChampion(null);
      setProStats([]);
      return;
    }

    setLoading(true);
    setError(null);
    setSelectedChampion(champion);

    try {
      const response = await axiosInstance.get(
        `/pro-stats/?champion=${champion}&start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}`
      );

      setProStats(response.data.stats || []);
    } catch (err) {
      console.error("Error fetching pro stats:", err);
      setError(err.response?.data?.error || "Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const diff = endDate.getTime() - startDate.getTime();
    const newStartDate2 = new Date(startDate.getTime() - diff);
    const newEndDate2 = new Date(endDate.getTime() - diff);

    setStartDate2(newStartDate2);
    setEndDate2(newEndDate2);
  }, [startDate, endDate]);

  const calculateStats = (games) => {
    if (!games.length) return {};

    const stats = {
      csPerMin: {
        "0-5": 0,
        "5-10": 0,
        "10-15": 0,
        "0-15": 0,
      },
      csDiff: {
        "0-5": 0,
        "5-10": 0,
        "10-15": 0,
        "0-15": 0,
      },
      goldDiff: {
        GD5: 0,
        GD10: 0,
        GD15: 0,
      },
      firstDragon: 0,
      firstVoidGrub: 0,
    };

    games.forEach((game) => {
      stats.csPerMin["0-5"] += game.CS5 / 5;
      stats.csPerMin["5-10"] += (game.CS10 - game.CS5) / 5;
      stats.csPerMin["10-15"] += (game.CS15 - game.CS10) / 5;
      stats.csPerMin["0-15"] += game.CS15 / 15;

      stats.csDiff["0-5"] += (game.CS5 - game.eCS5) / 5;
      stats.csDiff["5-10"] += (game.CS10 - game.CS5 - (game.eCS10 - game.eCS5)) / 5;
      stats.csDiff["10-15"] += (game.CS15 - game.CS10 - (game.eCS15 - game.eCS10)) / 5;
      stats.csDiff["0-15"] += (game.CS15 - game.eCS15) / 15;

      stats.goldDiff.GD5 += game.GD5;
      stats.goldDiff.GD10 += game.GD10;
      stats.goldDiff.GD15 += game.GD15;

      stats.firstDragon += game.FirstDragon ? 1 : 0;
      stats.firstVoidGrub += game.FirstVoidGrub ? 1 : 0;
    });

    const gameCount = games.length;

    stats.csPerMin["0-5"] /= gameCount;
    stats.csPerMin["5-10"] /= gameCount;
    stats.csPerMin["10-15"] /= gameCount;
    stats.csPerMin["0-15"] /= gameCount;

    stats.csDiff["0-5"] /= gameCount;
    stats.csDiff["5-10"] /= gameCount;
    stats.csDiff["10-15"] /= gameCount;
    stats.csDiff["0-15"] /= gameCount;

    stats.goldDiff.GD5 /= gameCount;
    stats.goldDiff.GD10 /= gameCount;
    stats.goldDiff.GD15 /= gameCount;

    stats.firstDragon = (stats.firstDragon / gameCount) * 100; // Percentage
    stats.firstVoidGrub = (stats.firstVoidGrub / gameCount) * 100; // Percentage

    return stats;
  };

  // Function to group and aggregate stats by champion
  const calculateChampionStats = (games) => {
    const statsByChampion = {};

    games.forEach((game) => {
      const champion = game.champion_name;
      if (!statsByChampion[champion]) {
        statsByChampion[champion] = {
          gamesPlayed: 0,
          winCount: 0,
          csPerMin: { "0-5": 0, "5-10": 0, "10-15": 0 },
          csDiff: { "0-5": 0, "5-10": 0, "10-15": 0 },
          goldDiff: { GD5: 0, GD10: 0, GD15: 0 },
          firstDragon: 0,
          firstVoidGrub: 0,
        };
      }

      statsByChampion[champion].gamesPlayed += 1;
      statsByChampion[champion].winCount += game.win;
      statsByChampion[champion].csPerMin["0-5"] += game.CS5 / 5;
      statsByChampion[champion].csPerMin["5-10"] += (game.CS10 - game.CS5) / 5;
      statsByChampion[champion].csPerMin["10-15"] += (game.CS15 - game.CS10) / 5;
      statsByChampion[champion].csDiff["0-5"] += (game.CS5 - game.eCS5);
      statsByChampion[champion].csDiff["5-10"] +=
        (game.CS10 - game.CS5 - (game.eCS10 - game.eCS5)) ;
      statsByChampion[champion].csDiff["10-15"] +=
        (game.CS15 - game.CS10 - (game.eCS15 - game.eCS10));
      statsByChampion[champion].goldDiff.GD5 += game.GD5;
      statsByChampion[champion].goldDiff.GD10 += game.GD10;
      statsByChampion[champion].goldDiff.GD15 += game.GD15;
      statsByChampion[champion].firstDragon += game.FirstDragon ? 1 : 0;
      statsByChampion[champion].firstVoidGrub += game.FirstVoidGrub ? 1 : 0;
    });

    return Object.keys(statsByChampion)
      .map((champion) => {
        const champData = statsByChampion[champion];
        const games = champData.gamesPlayed;

        return {
          champion,
          gamesPlayed: games,
          winRate: ((champData.winCount / games) * 100).toFixed(0),
          csPerMin: {
            "0-5": (champData.csPerMin["0-5"] / games).toFixed(2),
            "5-10": (champData.csPerMin["5-10"] / games).toFixed(2),
            "10-15": (champData.csPerMin["10-15"] / games).toFixed(2),
          },
          csDiff: {
            "0-5": (champData.csDiff["0-5"] / games).toFixed(2),
            "5-10": (champData.csDiff["5-10"] / games).toFixed(2),
            "10-15": (champData.csDiff["10-15"] / games).toFixed(2),
          },
          goldDiff: {
            GD5: (champData.goldDiff.GD5 / games).toFixed(2),
            GD10: (champData.goldDiff.GD10 / games).toFixed(2),
            GD15: (champData.goldDiff.GD15 / games).toFixed(2),
          },
          firstDragonRate: ((champData.firstDragon / games) * 100).toFixed(2),
          firstVoidGrubRate: ((champData.firstVoidGrub / games) * 100).toFixed(2),
        };
      })
      .sort((a, b) => b.gamesPlayed - a.gamesPlayed); // Sort by games played descending
  };


  useEffect(() => {
    if (!data || !Array.isArray(data)) return;

    const currentGames = data.filter((game) => {
      const gameDate = new Date(game.date);
      return gameDate >= startDate && gameDate <= endDate;
    });

    const previousGames = data.filter((game) => {
      const gameDate = new Date(game.date);
      return gameDate >= startDate2 && gameDate <= endDate2;
    });
    setChampionStats(calculateChampionStats(currentGames));
    setCurrentStats(calculateStats(currentGames));
    setPreviousStats(calculateStats(previousGames));
  }, [data, startDate, endDate, startDate2, endDate2]);

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 59, 59, 999);
    setEndDate(selectedDate);
  };

  const handleStartDate2Change = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0);
    setStartDate2(selectedDate);
  };

  const handleEndDate2Change = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 59, 59, 999);
    setEndDate2(selectedDate);
  };


  return (
    <div style={{ backgroundColor: 'rgb(67, 62, 62)', color: 'white', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          Current
          <CustomDateInput
            value={startDate?.toISOString().split('T')[0]}
            onChange={handleStartDateChange}
            style={{ marginLeft: '10px' }}
          />
          -
          <CustomDateInput
            value={endDate?.toISOString().split('T')[0]}
            onChange={handleEndDateChange}
            style={{ marginLeft: '0px' }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
          Previous
          <CustomDateInput
            value={startDate2?.toISOString().split('T')[0]}
            onChange={handleStartDate2Change}
            style={{ marginLeft: '10px' }}
          />
          -
          <CustomDateInput
            value={endDate2?.toISOString().split('T')[0]}
            onChange={handleEndDate2Change}
            style={{ marginLeft: '0px' }}
          />
        </div>
      </div>

    <table style={{ width: '100%', color: 'black', backgroundColor: "white", textAlign: 'center', marginTop: '20px' }}>
      <thead>
        <tr>
          <th>CS/M 0-5</th>
          <th>CS/M 5-10</th>
          <th>CS/M 10-15</th>
          <th>CS/M 0-15</th>
          <th>CSD 0-5</th>
          <th>CSD 5-10</th>
          <th>CSD 10-15</th>
          <th>CSD 0-15</th>
          <th>GD@5</th>
          <th>GD@10</th>
          <th>GD@15</th>
          <th>1st Dragon</th>
          <th>1st VoidGrub</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{currentStats?.csPerMin?.['0-5']?.toFixed(2)} ({previousStats?.csPerMin?.['0-5']?.toFixed(2)})</td>
          <td>{currentStats?.csPerMin?.['5-10']?.toFixed(2)} ({previousStats?.csPerMin?.['5-10']?.toFixed(2)})</td>
          <td>{currentStats?.csPerMin?.['10-15']?.toFixed(2)} ({previousStats?.csPerMin?.['10-15']?.toFixed(2)})</td>
          <td>{currentStats?.csPerMin?.['0-15']?.toFixed(2)} ({previousStats?.csPerMin?.['0-15']?.toFixed(2)})</td>
          <td>{currentStats?.csDiff?.['0-5']?.toFixed(2)} ({previousStats?.csDiff?.['0-5']?.toFixed(2)})</td>
          <td>{currentStats?.csDiff?.['5-10']?.toFixed(2)} ({previousStats?.csDiff?.['5-10']?.toFixed(2)})</td>
          <td>{currentStats?.csDiff?.['10-15']?.toFixed(2)} ({previousStats?.csDiff?.['10-15']?.toFixed(2)})</td>
          <td>{currentStats?.csDiff?.['0-15']?.toFixed(2)} ({previousStats?.csDiff?.['0-15']?.toFixed(2)})</td>
          <td>{currentStats?.goldDiff?.GD5?.toFixed(2)} ({previousStats?.goldDiff?.GD5?.toFixed(2)})</td>
          <td>{currentStats?.goldDiff?.GD10?.toFixed(2)} ({previousStats?.goldDiff?.GD10?.toFixed(2)})</td>
          <td>{currentStats?.goldDiff?.GD15?.toFixed(2)} ({previousStats?.goldDiff?.GD15?.toFixed(2)})</td>
          <td>{currentStats?.firstDragon?.toFixed(2)}% ({previousStats?.firstDragon?.toFixed(2)}%)</td>
          <td>{currentStats?.firstVoidGrub?.toFixed(2)}% ({previousStats?.firstVoidGrub?.toFixed(2)}%)</td>
        </tr>
      </tbody>
    </table>
    <br></br>
    <table style={{ width: "100%", color: "black", backgroundColor: "white", textAlign: "center" }}>
        <thead>
          <tr>
            <th>Champion</th>
            <th>Games Played</th>
            <th>Win Rate</th>
            <th>CS/M 0-5</th>
            <th>CS/M 5-10</th>
            <th>CS/M 10-15</th>
            <th>CSD 0-5</th>
            <th>CSD 5-10</th>
            <th>CSD 10-15</th>
            <th>GD@5</th>
            <th>GD@10</th>
            <th>GD@15</th>
            <th>1st Dragon</th>
            <th>1st VoidGrub</th>
          </tr>
        </thead>
        <tbody>
          {championStats.map((champ) => (
            <React.Fragment key={champ.champion}>
            <tr key={champ.champion} style={{ cursor: 'pointer' }}onClick={() => handleFetchProStats(champ.champion)}>
              <td style={{textAlign: 'start'}}><img src={championPhotos[champ.champion]} alt={champ.champion} className="small-image" />{champ.champion}</td>
              <td>{champ.gamesPlayed}</td>
              <td>{champ.winRate}%</td>
              <td>{champ.csPerMin["0-5"]}</td>
              <td>{champ.csPerMin["5-10"]}</td>
              <td>{champ.csPerMin["10-15"]}</td>
              <td>{champ.csDiff["0-5"]}</td>
              <td>{champ.csDiff["5-10"]}</td>
              <td>{champ.csDiff["10-15"]}</td>
              <td>{champ.goldDiff.GD5}</td>
              <td>{champ.goldDiff.GD10}</td>
              <td>{champ.goldDiff.GD15}</td>
              <td>{champ.firstDragonRate}%</td>
              <td>{champ.firstVoidGrubRate}%</td>
            </tr>
          {selectedChampion === champ.champion && (
                <>
                  {loading && (
                    <tr>
                      <td colSpan={14}>Loading professional stats...</td>
                    </tr>
                  )}
                  {error && (
                    <tr>
                      <td colSpan={14} style={{ color: 'red' }}>{error}</td>
                    </tr>
                  )}
                  {!loading && !error && proStats.length > 0 && (
                    proStats.sort((a, b) => b.gamesPlayed - a.gamesPlayed).map((proStat, index) => (
                      <tr key={`${champ.champion}-pro-${index}`} style={{ backgroundColor: '#f0f0f0' }}>
                        <td style={{textAlign: 'start'}}>{proStat.name} ({proStat.region})</td>
                        <td>{proStat.gamesPlayed}</td>
                        <td>{proStat.winRate.toFixed(0)}%</td>
                        <td>{proStat.avgCsPerMin_05?.toFixed(2)}</td>
                        <td>{proStat.avgCsPerMin_510?.toFixed(2)}</td>
                        <td>{proStat.avgCsPerMin_1015?.toFixed(2)}</td>
                        <td>{proStat.avgCsDiff_05?.toFixed(2)}</td>
                        <td>{proStat.avgCsDiff_510?.toFixed(2)}</td>
                        <td>{proStat.avgCsDiff_1015?.toFixed(2)}</td>

                        <td>{proStat.avgGoldDiff_5?.toFixed(2)}</td>
                        <td>{proStat.avgGoldDiff_10?.toFixed(2)}</td>
                        <td>{proStat.avgGoldDiff_15?.toFixed(2)}</td>
                        <td>{proStat.firstDragonRate?.toFixed(2)}%</td>
                        <td>{proStat.firstVoidGrubRate?.toFixed(2)}%</td>
                      </tr>
                    ))
                  )}
                </>
              )}
                      </React.Fragment>
                    ))}
        </tbody>
      </table>
    </div>
  );
};

export default SoloQReportComponent;
