import React, { useState, useEffect } from "react";
import axiosInstance from './backend/axiosInstance';
import { useSeason } from './SeasonContext';
import CustomSelect from './base/CustomSelect';
import iconDictionary from "./data/Icons";
import { championData, championPhotos } from "./data/ChampionsIcon";
import { TeamIcons } from './data/TeamIcons';
import roleIcon from "./data/Role";
const ProviewComponent = () => {
  const [matchData, setMatchData] = useState([]);
  const [filters, setFilters] = useState({
    patch: [],
    summonerName: [],
    enemySummonerName: [],
    teamName: [],
    enemyTeamName: [],
    championName: [],
    enemyChampionName: [],
  });

  const [filteredData, setFilteredData] = useState([]);
  const [options, setOptions] = useState({
    patch: [],
    summonerName: [],
    enemySummonerName: [],
    teamName: [],
    enemyTeamName: [],
    championName: [],
    enemyChampionName: [],
  });
  const { currentSeasonTime } = useSeason();

  useEffect(() => {
    // Fetch match data from the API
    axiosInstance
      .get('proview/')
      .then(response => {
        const data = response.data;
        setMatchData(data);
        setFilteredData(data);  // Initially, display all data

        // Dynamically generate options based on the match data
        setOptions({
          patch: [...new Set(data.map(match => match.patch))].map(patch => ({ value: patch, label: patch })),
          summonerName: [...new Set(data.map(match => match.summonerName))].map(name => ({ value: name, label: name })),
          enemySummonerName: [...new Set(data.map(match => match.enemySummonerName))].map(name => ({ value: name, label: name })),
          teamName: [...new Set(data.map(match => match.teamName))].map(name => ({ value: name, label: name })),
          enemyTeamName: [...new Set(data.map(match => match.enemyTeamName))].map(name => ({ value: name, label: name })),
          championName: [...new Set(data.map(match => match.championName))].map(name => ({ value: name, label: name })),
          enemyChampionName: [...new Set(data.map(match => match.enemyChampionName))].map(name => ({ value: name, label: name })),
        });
        })
      .catch(error => {
        console.error(error);
      });
  }, [currentSeasonTime]);

  const handleFilterChange = (name, selectedOptions) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters, [name]: selectedOptions };
      applyFilters(newFilters);
      return newFilters;
    });
  };

  const handleMatchDetailsClick = (roflLink) => {
    if (roflLink) {
      window.open(roflLink, "_blank"); // Opens the ROFL link in a new tab
    } else {
      alert("No Match Details link available.");
    }
  };

  // Function to apply filters to match data
  const applyFilters = (filters) => {
    let filtered = [...matchData];

    // Apply each filter to the data
    if (filters.patch.length) {
      filtered = filtered.filter(match => filters.patch.includes(match.patch));
    }
    if (filters.summonerName.length) {
      filtered = filtered.filter(match => filters.summonerName.some(name => match.summonerName.includes(name)));
    }
    if (filters.enemySummonerName.length) {
      filtered = filtered.filter(match => filters.enemySummonerName.some(name => match.enemySummonerName.includes(name)));
    }
    if (filters.teamName.length) {
      filtered = filtered.filter(match => filters.teamName.some(team => match.teamName.includes(team)));
    }
    if (filters.enemyTeamName.length) {
      filtered = filtered.filter(match => filters.enemyTeamName.some(team => match.enemyTeamName.includes(team)));
    }
    if (filters.championName.length) {
      filtered = filtered.filter(match => filters.championName.some(team => match.championName.includes(team)));

    }
    if (filters.enemyChampionName.length) {
      filtered = filtered.filter(match => filters.enemyChampionName.some(team => match.enemyChampionName.includes(team)));
    }

    setFilteredData(filtered);
  };


  const handleCreatePlaylist = () => {
    if (!filteredData.length) {
      alert("No matches found to add to the playlist.");
      return;
    }

    // Generate playlist name based on selected filters
    let nameParts = [];

    if (filters.championName.length === 1) {
      nameParts.push(filters.championName[0]); // Include champion name if one is selected
    }

    if (filters.enemyChampionName.length === 1) {
      if (nameParts.length) {
        nameParts.push(`vs ${filters.enemyChampionName[0]}`); // Add "vs" for enemy champion
      } else {
        nameParts.push(`vs ${filters.enemyChampionName[0]}`);
      }
    }

    if (filters.teamName.length === 1) {
      nameParts.push(filters.teamName[0]); // Include team name
    }

    if (filters.enemyTeamName.length === 1) {
      if (nameParts.length) {
        nameParts.push(`vs ${filters.enemyTeamName[0]}`); // Add "vs" for enemy team
      } else {
        nameParts.push(`vs ${filters.enemyTeamName[0]}`);
      }
    }

    if (filters.patch.length === 1) {
      nameParts.push(filters.patch[0]); // Add patch number
    }

    const generatedName = nameParts.length > 0 ? `${nameParts.join(" ")} playlist` : "Proview Playlist";

    const videoIds = filteredData.map((match) => match.youtube);
    const playlistUrl = `https://www.youtube.com/watch_videos?video_ids=${videoIds.join(
      ","
    )}&title=${encodeURIComponent(generatedName)}`;

    window.open(playlistUrl, "_blank");
  };

  console.log(options)
  return (
    <div style={{color: "white", borderColor: "pink" }}>
      <div className='filters' style={{marginBottom: "20px"}}>
      Champ
                <div className='short-chooser'>
        <CustomSelect
          options={options.championName}
          selectedOption={filters.championName.map(value => ({ value, label: value }))}
          onOptionChange={(selectedOptions) => handleFilterChange('championName', selectedOptions.map(option => option.value))}
        />
      </div>

        Verss
        <div className='short-chooser'>
        <CustomSelect
          options={options.enemyChampionName}
          selectedOption={filters.enemyChampionName.map(value => ({ value, label: value }))}
          onOptionChange={(selectedOptions) => handleFilterChange('enemyChampionName', selectedOptions.map(option => option.value))}
        />
      </div>
        Player
        <div className='short-chooser'>
          <CustomSelect
            options={options.summonerName}
            selectedOption={filters.summonerName.map(value => ({ value, label: value }))}
            onOptionChange={(selectedOptions) => handleFilterChange('summonerName', selectedOptions.map(option => option.value))}
          />
        </div>

        Enemy
        <div className='short-chooser'>
          <CustomSelect
            options={options.enemySummonerName}
            selectedOption={filters.enemySummonerName.map(value => ({ value, label: value }))}
            onOptionChange={(selectedOptions) => handleFilterChange('enemySummonerName', selectedOptions.map(option => option.value))}
          />
        </div>
        Patch
        <div className='short-chooser'>
          <CustomSelect
            options={options.patch}
            selectedOption={filters.patch.map(value => ({ value, label: value }))}
            onOptionChange={(selectedOptions) => handleFilterChange('patch', selectedOptions.map(option => option.value))}
          />
        </div>



        <div>
            <button onClick={handleCreatePlaylist} style={{ padding: "10px", backgroundColor: "#ff0000", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>
              Create Playlist
            </button>
        </div>

      </div>
      <div className='match-list' style={{display: "flex", flexDirection: "column", marginBottom: "20px", gap:"20px"}}>
        {/* Render filtered match data */}
        {filteredData.map((match) => (
          <div key={match.id} className="match-item" style={{display: "flex", color: "black", backgroundColor: "white", padding: "10px"}}>

            <div style={{width: "8%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img  src={championPhotos[match.championName]} alt="" className="small-image"/>

              {match.championName}
            </div>

            <div style={{width: "4%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              vs
            </div>
            <div style={{width: "8%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img  src={championPhotos[match.enemyChampionName]} alt="" className="small-image"/>
              {match.enemyChampionName}
            </div>

            <div style={{width: "13%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img  src={TeamIcons[match.teamName]} alt="" className="small-image"/>

              {match.summonerName}
            </div>

            <div style={{width: "4%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              vs
            </div>
            <div style={{width: "13%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img  src={TeamIcons[match.enemyTeamName]} alt="" className="small-image"/>
              {match.enemySummonerName}
            </div>

            <div style={{width: "10%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              {match.patch}
            </div>

            <div style={{width: "20%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[0]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.top1]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[1]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.jgl1]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[2]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.mid1]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[3]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.bot1]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[4]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.sup1]} alt="" className="small-image"/>
              </div>
            </div>

            <div style={{width: "20%", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
            <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[0]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.top2]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[1]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.jgl2]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[2]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.mid2]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[3]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.bot2]} alt="" className="small-image"/>
              </div>
              <div style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
                <img src={roleIcon[4]}   alt={""} style={{width: "15px"}}/>
                <img  src={championPhotos[match.sup2]} alt="" className="small-image"/>
              </div>
            </div>

            <div style={{width: "20%", display: "flex", justifyContent: "space-around", textAlign: "center", alignItems: "center" }}>
              <button style={{ width: "25px", height: "25px" }} onClick={() => window.open(`https://www.youtube.com/watch?v=${match.youtube}`, '_blank')} className="account-button">
                <img style={{ width: "25px", height: "25px" }} src={iconDictionary["yt"]} alt="log icon" />
              </button>
                <button onClick={() => handleMatchDetailsClick(String('/match/' + match.gameId))}>Match Details</button>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default ProviewComponent;
