import React, { useEffect, useState, useRef } from 'react';
import { useSeason } from './SeasonContext';
import mapPhotsDictionary from './data/MapData';
import Slider from 'rc-slider';
import { TeamIcons } from './data/TeamIcons';
import roleIcon from './data/Role';
import { championData, championPhotos } from './data/ChampionsIcon';
import { useParams } from 'react-router-dom';
import axiosInstance from './backend/axiosInstance';
import Select from 'react-select';


function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
  const teamOrderDict = isBlueTeam ? {
      [firstChampion]: "BP1",
      [secondChampion]: "BP2",
      [thirdChampion]: "BP3",
      [fourthChampion]: "BP4",
      [fifthChampion]: "BP5",
  } : {
      [firstChampion]: "RP1",
      [secondChampion]: "RP2",
      [thirdChampion]: "RP3",
      [fourthChampion]: "RP4",
      [fifthChampion]: "RP5",
  };

  return [0, 1, 2, 3, 4].map(index => {
      const championKey = teamOrderDict[index];
      return (
          <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
              <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
              <img
                  src={championPhotos[championData[game[championKey]]]}
                  alt={""}
                  className="small-image"
              />
          </div>
      );
  });
}


const PlayerPositionAceumComponent = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "Player Position";
  const { name } = useParams();
  const [matchData, setMatchData] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);

  const [selectedGame, setSelectedGame] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [playerData, setPlayerData] = useState([]);
  const [playerPositionData, setPlayerPositionData] = useState([]);

  const [selectedTeamSide, setSelectedTeamSide] = useState({ value: "any", label: 'Any' });
  const [filterTeamSide, setFilterTeamSide] = useState(["any"]);
  const teamSideOptions = [
    { value: "any", label: 'Any' }, // Change the value to "any"
    { value: "team1", label: 'Blue' },
    { value: "team2", label: 'Red' },
  ];

  const [timeRange, setTimeRange] = useState([0, 0]); // Immediate slider value
  const [maxTime, setMaxTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState();





  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;


  const handleTeamSideChange = (selectedOption) => {
    setFilterTeamSide([selectedOption.value]); // Ensure that the value is wrapped in an array
    setSelectedTeamSide(selectedOption);
  };

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('officials_for_player/?start=' + start + "&end=" + end + '&player_name=' + encodeURIComponent(name))
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMatchData(sortedMatchData);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  }, [currentSeasonTime, name]);

  const handleGameSelect = (index) => {
    setSelectedGame(matchData[index]);
    setDropdownOpen(false); // Close dropdown after selection
    setCurrentIndex(index)
    fetchData(matchData[index].gameId); // Fetch ward data for the selected game
  };

  const goToPreviousGame = () => {
    if (currentIndex > 0) {
      handleGameSelect(currentIndex - 1);
    }
  };

  const goToNextGame = () => {
    if (currentIndex < matchData.length - 1) {
      handleGameSelect(currentIndex + 1);
    }
  };

  const fetchData = (gameId) => {
    axiosInstance.get(`playerposition/?gameId=${gameId}`)
      .then(response => {
        setPlayerPositionData(response.data);
        setMaxTime(Math.max(...response.data.map(pos => pos.time))); // Set max time based on data
        setTimeRange([0, Math.max(...response.data.map(pos => pos.time))]); // Initialize time range to full data
      })
      .catch(error => {
        console.error(error);
      });

      axiosInstance.get(`players/?gameId=${gameId}&info=1`)
      .then(response => {
        setPlayerData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };


  useEffect(() => {
    const newFilteredGames = matchData.filter((game) => {
      if (filterTeamSide.includes("any")) return true;
      if (filterTeamSide.includes("team1") && game.team1 === game.teamName) return true;
      if (filterTeamSide.includes("team2") && game.team2 === game.teamName) return true;
      return false;
    });
    console.log("Filtered games:", newFilteredGames);

    setFilteredGames(newFilteredGames);
  }, [filterTeamSide, matchData]);

  return (
    <div>
      <div className='filters' style={{ textAlign: 'center', marginBottom: '20px', color: "black" }}>
      <div style={{ marginTop: '10px' }}>
          <button
            className='button-top-page'
            onClick={goToPreviousGame}
            disabled={currentIndex === 0}
            style={{ marginRight: '15px', padding: '5px 10px' , width: "120px" }}
          >
            {"<-"}Previous
          </button>
        </div>


            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '700px',
                    height: '67px',
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {selectedGame ?  (
                <div style={{
                    border: '1px solid #ccc',
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    width: '700px',
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}>
                        <div
                            key={selectedGame.gameId}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ccc',
                                padding: "10px"
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{width: "120px"}}>
                                    {selectedGame.leagueName} {selectedGame.patch}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {renderChampions(selectedGame, selectedGame.B1R, selectedGame.B2R, selectedGame.B3R, selectedGame.B4R, selectedGame.B5R, true)}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                        <img className="small-image" src={TeamIcons[selectedGame.team1]} alt={selectedGame.team1} style={{ width: '25px', marginRight: '5px' }} />
                                        {selectedGame.team1} vs {selectedGame.team2}
                                        <img className="small-image" src={TeamIcons[selectedGame.team2]} alt={selectedGame.team2} style={{ width: '25px', marginLeft: '5px' }} />
                                    </div>
                                    {renderChampions(selectedGame, selectedGame.R1R, selectedGame.R2R, selectedGame.R3R, selectedGame.R4R, selectedGame.R5R, false)}
                                </div>
                            </div>
                        </div>
                </div>
            ) : 'Select a Game'}

                {dropdownOpen && (
                    <div
                        style={{
                            border: '1px solid #ccc',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 100,
                            width: '700px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            top: '67px',
                        }}
                    >
                        {matchData.map((game, index) => {
                            return (
                                <div
                                    key={game.gameId}
                                    onClick={() => handleGameSelect(index)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        transition: 'background 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.background = '#f0f0f0'}
                                    onMouseLeave={(e) => e.currentTarget.style.background = 'white'}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ width: '120px', textAlign: 'center' }}>
                                            {game.leagueName} {game.patch}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.B1R, game.B2R, game.B3R, game.B4R, game.B5R, true)}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                                <img className="small-image" src={TeamIcons[game.team1]} alt={`Team ${game.team1}`} style={{ width: '35px', marginRight: '10px' }} />
                                                <span style={{ fontWeight: 'bold' }}>{game.team1} vs {game.team2}</span>
                                                <img className="small-image" src={TeamIcons[game.team2]} alt={`Team ${game.team2}`} style={{ width: '35px', marginLeft: '10px' }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.R1R, game.R2R, game.R3R, game.R4R, game.R5R, false)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div style={{ marginTop: '10px' }}>
                <button
                className='button-top-page'
                onClick={goToNextGame}
                disabled={currentIndex === matchData.length - 1}
                style={{ marginLeft: '15px', padding: '5px 10px', width: "120px" }}
                >
                    Next {"->"}
                </button>
            </div>

      </div>

      Slider

      <div className='filters'>
          Side
          <div style={{padding: "10px", color: "black"}}>
            <Select
              value={selectedTeamSide}
              onChange={handleTeamSideChange}
              options={teamSideOptions}
              style={{color: "black"}}
            />
          </div>
        </div>


    </div>
  );
};

export default PlayerPositionAceumComponent;
