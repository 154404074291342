import React, { useEffect, useState, useRef, useMemo  } from 'react';
import { useParams } from 'react-router-dom';
import roleIcon from './data/Role'; // Adjust the path accordingly
import './Player.css';
import { championData, championPhotos } from "./data/ChampionsIcon";
import 'react-pivottable/pivottable.css';
import { TeamIcons } from './data/TeamIcons';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';
import { useSeason } from './SeasonContext';
import mapPhotsDictionary from './data/MapData';
import GIF from 'gif.js';
import axiosInstance from './backend/axiosInstance';
import CustomSelect from './base/CustomSelect';
import Select from 'react-select';

function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
    const teamOrderDict = isBlueTeam ? {
        [firstChampion]: "BP1",
        [secondChampion]: "BP2",
        [thirdChampion]: "BP3",
        [fourthChampion]: "BP4",
        [fifthChampion]: "BP5",
    } : {
        [firstChampion]: "RP1",
        [secondChampion]: "RP2",
        [thirdChampion]: "RP3",
        [fourthChampion]: "RP4",
        [fifthChampion]: "RP5",
    };

    return [0, 1, 2, 3, 4].map(index => {
        const championKey = teamOrderDict[index];
        return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
                <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
                <img
                    src={championPhotos[championData[game[championKey]]]}
                    alt={""}
                    className="small-image"
                />
            </div>
        );
    });
}

const Level1GameMap = ({ gameId, matchData, teamName }) => {
    const [playerPositionData, setPlayerPositionData] = useState([]);
    const [wardData, setWardData] = useState(null); // New state for ward data

    const minX = -120;
    const minY = -120;
    const maxX = 14970;
    const maxY = 14980;

    const [mapWidth, setMapWidth] = useState((window.innerWidth)/8);

    useEffect(() => {
      const updateMapWidth = () => {
        // Use the window width minus 200, divided by 3, or fallback to the image width if rendered
        const calculatedWidth = (window.innerWidth - 17 - 200) / 4;
        setMapWidth(calculatedWidth);
      };

      updateMapWidth(); // Set the initial width

      window.addEventListener('resize', updateMapWidth);
      return () => window.removeEventListener('resize', updateMapWidth);
    }, []);

    const translateCoordinates = (x, y) => {
      const newX = ((x - minX) / (maxX - minX)) * mapWidth;
      const newY = ((maxY - y) / (maxY - minY)) * mapWidth;
      return { newX, newY };
    };

    useEffect(() => {
        const times = [30, 45, 60, 75];
        const requests = times.map(time => axiosInstance.get(`playerposition/?gameId=${gameId}&time=${time}`));

        Promise.all(requests)
          .then(responses => {
            const combinedData = responses.flatMap(res => res.data);
            setPlayerPositionData(combinedData);
          })
          .catch(error => console.error("Error fetching player data:", error));
          axiosInstance.get(`playerwards_official/?gameId=${gameId}&short=1`)
          .then(response => {
              setWardData(response.data); // Set ward data
          })
          .catch(error => {
              console.error('Error fetching ward data:', error);
          });
      }, [gameId]);


        return (
      <div style={{width: "100%"}}>
        <div style={{display: 'flex'}}>
        <div  style={{ position: "relative", marginBottom: "0px" }}>
            <img
              src={mapPhotsDictionary["SummonersRift"]}
              alt="Summoner’s Rift"
              style={{ width: mapWidth+"px", height: mapWidth+"px" }}
              />
                {wardData && wardData.filter((ward) => {return ward.placeTime <= 30  && (!ward.killTime || ward.killTime >= 30 );}).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                    return (
                        <div
                            key={`ward-${index}`}
                            style={{
                                position: 'absolute',
                                top: `${newY - 5}px`,
                                left: `${newX - 5}px`,
                                width: '10px',
                                height: '10px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                                color: 'white',
                            }}
                        >
                            {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                        </div>
                    );
                    }
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "45%",
                        color: 'white'
                        }}>
                        {Math.floor(30 / 60)}:{String(30 % 60).padStart(2, '0')}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "20%",
                        color: 'blue',
                        fontWeight: teamName===matchData.team1 ? "bold" : "normal",

                        }}>
                        <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team1}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "70%",
                        color: 'red',
                        fontWeight: teamName===matchData.team2 ? "bold" : "normal",
                        }}>
                        <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team2}
                    </div>
                )}
                {playerPositionData && playerPositionData.filter((ward) => {return ward.time === 30}).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y);
                    return (
                        <div
                        key={`player--${index}-t`}
                        style={{
                            position: 'absolute',
                            top: `${newY - 13}px`,
                            left: `${newX - 13}px`,
                            width: '26px',
                            height: '26px',
                            backgroundColor: position.side === (teamName===matchData.team1 ? "blue":"red") ? "purple" : 'transparent',

                            borderRadius: '50%',
                        }}
                        >

                            <div
                                key={`player--${index}`}
                                style={{
                                    position: 'absolute',
                                    top: `2px`,
                                    left: `2px`,
                                    width: '22px',
                                    height: '22px',
                                    backgroundColor: position.side,
                                    borderRadius: '50%',
                                }}
                                >

                                <img
                                    src={championPhotos[position.championName]}
                                    alt=""
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        position: 'absolute',
                                        borderRadius: '50%',
                                        top: '1px',
                                        left: '1px',
                                    }}
                                    />
                            </div>
                        </div>
                    );
                    }
                )}

            </div>
          <div  style={{ position: "relative", marginBottom: "0px" }}>
            <img
              src={mapPhotsDictionary["SummonersRift"]}
              alt="Summoner’s Rift"
              style={{ width: mapWidth+"px", height: mapWidth+"px" }}
              />
                {wardData && wardData.filter((ward) => {return ward.placeTime <= 45  && (!ward.killTime || ward.killTime >= 45 );}).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                    return (
                        <div
                            key={`ward-${index}`}
                            style={{
                                position: 'absolute',
                                top: `${newY - 5}px`,
                                left: `${newX - 5}px`,
                                width: '10px',
                                height: '10px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                                color: 'white',
                            }}
                        >
                            {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                        </div>
                    );
                    }
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "45%",
                        color: 'white'
                        }}>
                        {Math.floor(45 / 60)}:{String(45 % 60).padStart(2, '0')}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "20%",
                        color: 'blue',
                        fontWeight: teamName===matchData.team1 ? "bold" : "normal",

                        }}>
                        <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team1}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "70%",
                        color: 'red',
                        fontWeight: teamName===matchData.team2 ? "bold" : "normal",
                        }}>
                        <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team2}
                    </div>
                )}
                {playerPositionData && playerPositionData.filter((ward) => {return ward.time === 45}).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                    return (
                        <div
                        key={`player--${index}-t`}
                        style={{
                            position: 'absolute',
                            top: `${newY - 13}px`,
                            left: `${newX - 13}px`,
                            width: '26px',
                            height: '26px',
                            backgroundColor: position.side === (teamName===matchData.team1 ? "blue":"red") ? "purple" : 'transparent',

                            borderRadius: '50%',
                        }}
                        >

                            <div
                                key={`player--${index}`}
                                style={{
                                    position: 'absolute',
                                    top: `2px`,
                                    left: `2px`,
                                    width: '22px',
                                    height: '22px',
                                    backgroundColor: position.side,
                                    borderRadius: '50%',
                                }}
                                >

                                <img
                                    src={championPhotos[position.championName]}
                                    alt=""
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        position: 'absolute',
                                        borderRadius: '50%',
                                        top: '1px',
                                        left: '1px',
                                    }}
                                    />
                            </div>
                        </div>
                    );
                    }
                )}

            </div>
            <div  style={{ position: "relative", marginBottom: "0px" }}>
                <img
                src={mapPhotsDictionary["SummonersRift"]}
                alt="Summoner’s Rift"
                style={{ width: mapWidth+"px", height: mapWidth+"px" }}
                />
                {wardData && wardData.filter((ward) => {return ward.placeTime <= 60  && (!ward.killTime || ward.killTime >= 60 );}).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                    return (
                        <div
                            key={`ward-${index}`}
                            style={{
                                position: 'absolute',
                                top: `${newY - 5}px`,
                                left: `${newX - 5}px`,
                                width: '10px',
                                height: '10px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                                color: 'white',
                            }}
                        >
                            {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                        </div>
                    );
                    }
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "45%",
                        color: 'white'
                        }}>
                        {Math.floor(60 / 60)}:{String(60 % 60).padStart(2, '0')}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "20%",
                        color: 'blue',
                        fontWeight: teamName===matchData.team1 ? "bold" : "normal",

                        }}>
                        <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team1}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "70%",
                        color: 'red',
                        fontWeight: teamName===matchData.team2 ? "bold" : "normal",
                        }}>
                        <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team2}
                    </div>
                )}
                {playerPositionData && playerPositionData.filter((ward) => {return ward.time === 60}).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                    return (
                        <div
                        key={`player--${index}-t`}
                        style={{
                            position: 'absolute',
                            top: `${newY - 13}px`,
                            left: `${newX - 13}px`,
                            width: '26px',
                            height: '26px',
                            backgroundColor: position.side === (teamName===matchData.team1 ? "blue":"red") ? "purple" : 'transparent',

                            borderRadius: '50%',
                        }}
                        >

                            <div
                                key={`player--${index}`}
                                style={{
                                    position: 'absolute',
                                    top: `2px`,
                                    left: `2px`,
                                    width: '22px',
                                    height: '22px',
                                    backgroundColor: position.side,
                                    borderRadius: '50%',
                                }}
                                >

                                <img
                                    src={championPhotos[position.championName]}
                                    alt=""
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        position: 'absolute',
                                        borderRadius: '50%',
                                        top: '1px',
                                        left: '1px',
                                    }}
                                    />
                            </div>
                        </div>
                    );
                    }
                )}

            </div>
            <div  style={{ position: "relative", marginBottom: "0px" }}>
                <img
                src={mapPhotsDictionary["SummonersRift"]}
                alt="Summoner’s Rift"
                style={{ width: mapWidth+"px", height: mapWidth+"px" }}
                />

{wardData && wardData.filter(ward => ward.placeTime <= 75 && (!ward.killTime || ward.killTime >= 75)).map((position, idx) => {
                const { newX, newY } = translateCoordinates(position.x, position.y);
                return (
                    <div
                        key={`ward-${idx}`}
                        style={{
                            position: 'absolute',
                            top: `${newY - 5}px`,
                            left: `${newX - 5}px`,
                            width: '10px',
                            height: '10px',
                            backgroundColor: position.side,
                            borderRadius: '50%',
                            color: 'white',
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
            })}


                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "45%",
                        color: 'white'
                        }}>
                        {Math.floor(75 / 60)}:{String(75 % 60).padStart(2, '0')}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "20%",
                        color: 'blue',
                        fontWeight: teamName===matchData.team1 ? "bold" : "normal",

                        }}>
                        <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team1}
                    </div>
                )}
                {playerPositionData && (
                    <div
                        key='timerr'
                        style={{
                        position: 'absolute',
                        top:  0,
                        left: "70%",
                        color: 'red',
                        fontWeight: teamName===matchData.team2 ? "bold" : "normal",
                        }}>
                        <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                        {matchData.team2}
                    </div>
                )}

                {playerPositionData && playerPositionData.filter(player => player.time === 75).map((position, idx) => {
                const { newX, newY, } = translateCoordinates(position.x, position.y);
                return (
                    <div
                    key={`player--${idx}-t`}
                    style={{
                        position: 'absolute',
                        top: `${newY - 13}px`,
                        left: `${newX - 13}px`,
                        width: '26px',
                        height: '26px',
                        backgroundColor: position.side === (teamName===matchData.team1 ? "blue":"red") ? "purple" : 'transparent',

                        borderRadius: '50%',
                    }}
                    >

                        <div
                            key={`player--${idx}`}
                            style={{
                                position: 'absolute',
                                top: `2px`,
                                left: `2px`,
                                width: '22px',
                                height: '22px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                            }}
                            >

                            <img
                                src={championPhotos[position.championName]}
                                alt=""
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    top: '1px',
                                    left: '1px',
                                }}
                                />
                        </div>
                    </div>
                );
            })}



            </div>
        </div>
        <div style={{display: 'flex', backgroundColor: "white", justifyContent: 'center', marginBottom: "20px"}}>
                {renderChampions(matchData, matchData.B1R, matchData.B2R, matchData.B3R, matchData.B4R, matchData.B5R, true)}
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <img className="small-image" src={TeamIcons[matchData.team1]} alt={matchData.team1} style={{ width: '25px', marginRight: '5px' }} />
                    {matchData.team1} vs {matchData.team2}
                    <img className="small-image" src={TeamIcons[matchData.team2]} alt={matchData.team2} style={{ width: '25px', marginLeft: '5px' }} />
                </div>
                {renderChampions(matchData, matchData.R1R, matchData.R2R, matchData.R3R, matchData.R4R, matchData.R5R, false)}
            </div>
      </div>
    );
  };

const Level1Component = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName } = useParams();

  document.title = "Level 1 " + teamName;



  const [matchData, setMatchData] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);


  const [selectedGame, setSelectedGame] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [wardData, setWardData] = useState(null); // New state for ward data
  const [playerPosition, setPlayerPosition] = useState(null); // New state for ward data
  const [gifLoading, setGifLoading] = useState(false);

  const [currentIndex, setCurrentIndex] = useState();

  const [currentTime, setCurrentTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);

  const [inputTime, setInputTime] = useState(45);
  const [inputTime2, setInputTime2] = useState(60);
  const [inputTime3, setInputTime3] = useState(75);

  const [timePoint1, setTimePoint1] = useState('0:30');
  const [timePoint2, setTimePoint2] = useState('1:30');

  const [selectedTeamSide, setSelectedTeamSide] = useState({ value: "any", label: 'Any' });
  const [filterTeamSide, setFilterTeamSide] = useState(["any"]);
  const teamSideOptions = [
    { value: "any", label: 'Any' }, // Change the value to "any"
    { value: "team1", label: 'Blue' },
    { value: "team2", label: 'Red' },
  ];

  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;

  const [mapWidth, setMapWidth] = useState((window.innerWidth)/3);

  useEffect(() => {
    const updateMapWidth = () => {
      // Use the window width minus 200, divided by 3, or fallback to the image width if rendered
      const calculatedWidth = (window.innerWidth - 17 - 200) / 3;
      setMapWidth(calculatedWidth);
    };

    updateMapWidth(); // Set the initial width

    // Listen for window resize and update map width accordingly
    window.addEventListener('resize', updateMapWidth);
    return () => window.removeEventListener('resize', updateMapWidth);
  }, []);

 const translateCoordinates = (x, y, mapSize) => {
    const newX = ((x - minX) / (maxX - minX)) * mapSize;
    const newY = ((maxY - y) / (maxY - minY)) * mapSize;
    return { newX, newY };
  };

  const handleTeamSideChange = (selectedOption) => {
    setFilterTeamSide([selectedOption.value]); // Ensure that the value is wrapped in an array
    setSelectedTeamSide(selectedOption);
  };

  const handleGameSelect = (index) => {
    setSelectedGame(matchData[index]);
    setDropdownOpen(false); // Close dropdown after selection
    setCurrentIndex(index)
    fetchData(matchData[index].gameId); // Fetch ward data for the selected game
    };

    const handleSetTime = (time, index) => {
        if (index===0)
        {
            setInputTime(time)
        }
        if (index===1)
        {
            setInputTime2(time)
        }
        if (index===1)
        {
            setInputTime3(time)
        }
    };

    const goToPreviousGame = () => {
        if (currentIndex > 0) {
            handleGameSelect(currentIndex - 1);
        }
      };

    const goToNextGame = () => {
        if (currentIndex < matchData.length - 1) {
            handleGameSelect(currentIndex + 1);
        }
      };

    const fetchData = (gameId) => {
        axiosInstance.get(`playerwards_official/?gameId=${gameId}&short=1`)
            .then(response => {
                setWardData(response.data); // Set ward data
            })
            .catch(error => {
                console.error('Error fetching ward data:', error);
            });
        axiosInstance.get(`playerposition/?gameId=${gameId}&short=1`)
            .then(response => {
                setPlayerPosition(response.data); // Set ward data
            })
            .catch(error => {
                console.error('Error fetching ward data:', error);
            });
    };

    const handleSliderChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        setCurrentTime(newValue);
        setSliderValue(newValue);
      };

  const captureScreenshot = (table) => {
    const element = document.getElementById(table);

    html2canvas(element)
      .then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'screenshot.png';
        link.href = image;
        link.click();
      })
      .catch(err => {
        console.error('Error capturing screenshot', err);
      });
  };

  const captureFrame = async () => {
    const mapElement = document.querySelector(".map-container"); // Your map container class
    return html2canvas(mapElement, {
        useCORS: true,
        scale: 1,  // To improve GIF quality
    });
};

  const makeGif = async () => {
    const start = parseTime(timePoint1);
    const end = parseTime(timePoint2);

    setGifLoading(true);

    // Initialize the GIF
    const gif = new GIF({
        workers: 2,
        quality: 1,
        workerScript: "/gif.worker.js",
    });

    try {

        // Loop through the time points to capture frames
        for (let time = start; time <= end; time += 1) {
            setCurrentTime(time);  // Simulate the slider time changing

            const canvas = await captureFrame();  // Capture the current frame
            if (canvas) {  // Check if the canvas is valid
                gif.addFrame(canvas, { delay: 1 });  // 100 ms per frame
            } else {
                console.warn(`No valid canvas returned for time: ${time}`);
            }
        }

        gif.on('finished', function (blob) {
            setGifLoading(false);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'map-animation.gif';
            link.click();
        });

        gif.render();
    } catch (error) {
        console.error("Error while creating GIF:", error);
        setGifLoading(false);
    }
};

  const parseTime = (timeStr) => {
    const [minutes, seconds] = timeStr.split(':').map(Number);
    return minutes * 60 + seconds;
  };

  const handleTimeChange = (e, setTime) => {
    const value = e.target.value;
    const validTime = value.match(/^([0-5]?[0-9]):([0-5]?[0-9])$/); // MM:SS format validation
    if (validTime) {
      setTime(value);
    }
  };

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('parsed_official_games/?start=' + start + "&end=" + end + '&team=' + teamName)
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMatchData(sortedMatchData);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }, [currentSeasonTime, teamName]);

    const renderWardsAndPlayers = (time, index, color, mapWidth2) => (
        <>
            {/* Wards */}
            {wardData && wardData.filter(ward => ward.placeTime <= time && (!ward.killTime || ward.killTime >= time)).map((position, idx) => {
                const { newX, newY } = translateCoordinates(position.x, position.y, mapWidth2);
                return (
                    <div
                        key={`ward-${index}-${idx}`}
                        style={{
                            position: 'absolute',
                            top: `${newY - 5}px`,
                            left: `${newX - 5}px`,
                            width: '10px',
                            height: '10px',
                            backgroundColor: position.side,
                            borderRadius: '50%',
                            color: 'white',
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
            })}
            {playerPosition && playerPosition.filter(player => player.time === time).map((position, idx) => {
                const { newX, newY, } = translateCoordinates(position.x, position.y, mapWidth2);
                return (
                    <div
                    key={`player-${index}-${idx}-t`}
                    style={{
                        position: 'absolute',
                        top: `${newY - 13}px`,
                        left: `${newX - 13}px`,
                        width: '26px',
                        height: '26px',
                        backgroundColor: position.side === color ? "purple" : 'transparent',
                        borderRadius: '50%',
                    }}
                    >

                        <div
                            key={`player-${index}-${idx}`}
                            style={{
                                position: 'absolute',
                                top: `2px`,
                                left: `2px`,
                                width: '22px',
                                height: '22px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                            }}
                            >

                            <img
                                src={championPhotos[position.championName]}
                                alt=""
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    top: '1px',
                                    left: '1px',
                                }}
                                />
                        </div>
                    </div>
                );
            })}
        </>
    );

    const renderTeamInfo = (name, icon, position, color) => (
        <div
            key={`team-${position}`}
            style={{
                position: 'absolute',
                left: position,
                top: 0,
                color: color,
                fontWeight: teamName===name ? "bold" : "normal",

            }}
        >
            <img
                src={icon}
                alt=""
                style={{
                    width: '20px',
                    height: '20px',
                    objectFit: 'contain',
                }}
            />
            {name}
        </div>
    );

    const renderTimer = (time, position) => (
        <div
            key={`timer-${position}`}
            style={{
                position: 'absolute',
                top: 0,
                left: position,
                color: 'white',
            }}
        >
            {Math.floor(time / 60)}:{String(time % 60).padStart(2, '0')}
        </div>
    );


    useEffect(() => {
        setFilteredGames(
          matchData.filter((game) => {
            if (filterTeamSide.includes("any")) return true;
            if (filterTeamSide.includes("team1") && game.team1 === teamName) return true;
            if (filterTeamSide.includes("team2") && game.team2 === teamName) return true;
            return false;
          })
        );
      }, [filterTeamSide, teamName, matchData]);

  return (
    <div>
        <div className='filters' style={{ textAlign: 'center', marginBottom: '20px', color: "black" }}>
        <div style={{ marginTop: '10px' }}>
          <button
            className='button-top-page'
            onClick={goToPreviousGame}
            disabled={currentIndex === 0}
            style={{ marginRight: '15px', padding: '5px 10px' , width: "120px" }}
          >
            {"<-"}Previous
          </button>
        </div>
            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '700px',
                    height: '67px',
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {selectedGame ?  (
                <div style={{
                    border: '1px solid #ccc',
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    width: '700px',
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}>
                        <div
                            key={selectedGame.gameId}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ccc',
                                padding: "10px"
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{width: "120px"}}>
                                    {selectedGame.leagueName} {selectedGame.patch}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {renderChampions(selectedGame, selectedGame.B1R, selectedGame.B2R, selectedGame.B3R, selectedGame.B4R, selectedGame.B5R, true)}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                        <img className="small-image" src={TeamIcons[selectedGame.team1]} alt={selectedGame.team1} style={{ width: '25px', marginRight: '5px' }} />
                                        {selectedGame.team1} vs {selectedGame.team2}
                                        <img className="small-image" src={TeamIcons[selectedGame.team2]} alt={selectedGame.team2} style={{ width: '25px', marginLeft: '5px' }} />
                                    </div>
                                    {renderChampions(selectedGame, selectedGame.R1R, selectedGame.R2R, selectedGame.R3R, selectedGame.R4R, selectedGame.R5R, false)}
                                </div>
                            </div>
                        </div>
                </div>
            ) : 'Select a Game'}

                {dropdownOpen && (
                    <div
                        style={{
                            border: '1px solid #ccc',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 100,
                            width: '700px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            top: '67px',
                        }}
                    >
                        {matchData.map((game, index) => {
                            return (
                                <div
                                    key={game.gameId}
                                    onClick={() => handleGameSelect(index)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        transition: 'background 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.background = '#f0f0f0'}
                                    onMouseLeave={(e) => e.currentTarget.style.background = 'white'}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ width: '120px', textAlign: 'center' }}>
                                            {game.leagueName} {game.patch}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.B1R, game.B2R, game.B3R, game.B4R, game.B5R, true)}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                                <img className="small-image" src={TeamIcons[game.team1]} alt={`Team ${game.team1}`} style={{ width: '35px', marginRight: '10px' }} />
                                                <span style={{ fontWeight: 'bold' }}>{game.team1} vs {game.team2}</span>
                                                <img className="small-image" src={TeamIcons[game.team2]} alt={`Team ${game.team2}`} style={{ width: '35px', marginLeft: '10px' }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.R1R, game.R2R, game.R3R, game.R4R, game.R5R, false)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div style={{ marginTop: '10px' }}>
                <button
                className='button-top-page'
                onClick={goToNextGame}
                disabled={currentIndex === matchData.length - 1}
                style={{ marginLeft: '15px', padding: '5px 10px', width: "120px" }}
                >
                    Next {"->"}
                </button>
            </div>
        </div>


        <div style={{alignItems: 'center',  position: 'relative', width: "100%", display: 'flex', flexDirection: 'column'}}>
            <div id="map-container" className="map-container" style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>

            <img src={mapPhotsDictionary["SummonersRift"]} alt='Summoner’s Rift' style={{  position: 'relative', width: "500px", height: "500px" }} />

            {wardData && wardData.filter((ward) => {return ward.placeTime <= currentTime && (!ward.killTime || ward.killTime >= currentTime);}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                return (
                    <div
                        key={index}
                        style={{
                        position: 'absolute',
                        top: newY-5 + 'px',
                        left: newX-5 + 'px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: position.side,
                        borderRadius: '50%',
                        color: 'white'
                        }}
                    >
                        {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                    </div>
                );
                }
            )}
            {selectedGame && (
                <div
                    key='timerr'
                    style={{
                    position: 'absolute',
                    top:  0,
                    left: "45%",
                    color: 'white'
                    }}>
                    {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
                </div>
            )}
            {selectedGame && (
                <div
                    key='timerr'
                    style={{
                    position: 'absolute',
                    top:  0,
                    left: "20%",
                    color: 'blue',
                    fontWeight: teamName===selectedGame.team1 ? "bold" : "normal",

                    }}>
                    <img src={TeamIcons[selectedGame.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                    {selectedGame.team1}
                </div>
            )}
            {selectedGame && (
                <div
                    key='timerr'
                    style={{
                    position: 'absolute',
                    top:  0,
                    left: "70%",
                    color: 'red',
                    fontWeight: teamName===selectedGame.team2 ? "bold" : "normal",
                    }}>
                    <img src={TeamIcons[selectedGame.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                    {selectedGame.team2}
                </div>
            )}
            {playerPosition && playerPosition.filter((ward) => {return ward.time === currentTime;}).map((position, index) => {
                const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                return (
                    <div
                    key={`player-${index}--t`}
                    style={{
                        position: 'absolute',
                        top: `${newY - 13}px`,
                        left: `${newX - 13}px`,
                        width: '26px',
                        height: '26px',
                        backgroundColor: position.side === (teamName===selectedGame.team1 ? "blue":"red") ? "purple" : 'transparent',
                        borderRadius: '50%',
                    }}
                    >

                        <div
                            key={`player-${index}-`}
                            style={{
                                position: 'absolute',
                                top: `2px`,
                                left: `2px`,
                                width: '22px',
                                height: '22px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                            }}
                            >

                            <img
                                src={championPhotos[position.championName]}
                                alt=""
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    top: '1px',
                                    left: '1px',
                                }}
                                />
                        </div>
                    </div>
                );
                }
            )}
            </div>
            <input
                    type="range"
                    min="0"
                    max="180"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    style={{width: "500px"}}
                    />
            <div style={{ color: "white", textAlign: 'center' }}>
                {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
            </div>

            <div style={{display: 'flex'}}>

                <input
                    type="text"
                    value={timePoint1}
                    onChange={e => handleTimeChange(e, setTimePoint1)}
                    placeholder="M:SS"
                    style={{ width: "60px", marginRight: "10px" }}
                    />
                <label style={{color: 'white'}}> - </label>
                <input
                type="text"
                value={timePoint2}
                onChange={e => handleTimeChange(e, setTimePoint2)}
                placeholder="M:SS"
                style={{ width: "60px", marginLeft: "10px" }}
                />
                <button
                className='button-top-page'
                onClick={makeGif}
                disabled={gifLoading}
                >
                {gifLoading ? "Processing..." : "Make GIF"}
                </button>
                <button  onClick={() => captureScreenshot('map-container')}>ScreenShot</button>

            </div>
        </div>
        <div  style={{width: '100%' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', backgroundColor: 'white' }}>
                {[inputTime, inputTime2, inputTime3].map((time, index) => (
                    <div key={index} style={{ width:  `${100 / 3}%`}}>
                        <input
                            style={{ width: '100%', textAlign: 'center' }}
                            value={time}
                            onChange={(e) => handleSetTime(e.target.value, index)}
                        />
                    </div>
                ))}
            </div>
            <div id="level1-container" style={{ display: 'flex', width: '100%' }}>

                {[inputTime, inputTime2, inputTime3].map((time, index) => (
                    <div key={index} style={{ width: `${100 / 3}%`, position: 'relative' }}>
                        <img style={{ width: '100%' }} src={mapPhotsDictionary['SummonersRift']} alt="Summoner's Rift" />
                        {selectedGame && renderTimer(time, '45%')}
                        {selectedGame && renderTeamInfo(selectedGame.team1, TeamIcons[selectedGame.team1], '20%', 'blue')}
                        {selectedGame && renderTeamInfo(selectedGame.team2, TeamIcons[selectedGame.team2], '70%', 'red')}

                        {selectedGame && renderWardsAndPlayers(time, index, teamName===selectedGame.team1 ? "blue":"red", mapWidth)}
                    </div>
                ))}
            </div>
        </div>
        <button style={{width: "100%"}} className='button-top-page' onClick={() => captureScreenshot('level1-container')}>ScreenShot</button>
        Level 1

        <div className='filters'>
          Side
          <div style={{padding: "10px", color: "black"}}>
            <Select
              value={selectedTeamSide}
              onChange={handleTeamSideChange}
              options={teamSideOptions}
              style={{color: "black"}}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" , justifyContent: "space-between"}}>
        {filteredGames.map((game, idx) => (
            <Level1GameMap
                key={`${game.gameId}-${teamName}`} // Ensure unique keys for re-renders
                gameId={game.gameId}
                matchData={game} // Ensure matchData updates with filteredGames
                teamName={teamName}
                />
        ))}
      </div>
    </div>
  );
};

export default Level1Component;
