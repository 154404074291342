const itemIconDictionary = {};

function importAll(r) {
  r.keys().forEach((key) => {
    const iconName = 'img' + key.replace('./', '').replace('.png', ''); // Prepend 'img' to the file name
    itemIconDictionary[iconName] = r(key); // Assign the imported image to the dictionary
  });
}

importAll(require.context('../../item_photos', false, /\.png$/));

export default itemIconDictionary;
