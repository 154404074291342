// SeasonContext.js
import React, { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';

// Create a context
const SeasonContext = createContext();

// Custom hook to use the SeasonContext
export const useSeason = () => {
  return useContext(SeasonContext);
};

// Context provider component
export const SeasonProvider = ({ children }) => {
  const [currentSeason, setCurrentSeason] = useState(() => {
    const savedSeason = localStorage.getItem('currentSeason');
    return savedSeason ? savedSeason : "S15 Winter";
  });

  const initialMount = useRef(true);

  const seasonOptions = [
    { label: "S14 Spring", time: "01.01.2024 - 15.05.2024" },
    { label: "S14 Summer", time: "15.05.2024 - 09.12.2024" },
    { label: "S15 Winter", time: "09.12.2024 - 15.05.2025" },
  ];

  const currentSeasonTime = useMemo(() => {
    const season = seasonOptions.find(season => season.label === currentSeason);
    return season ? season.time : '';
  }, [currentSeason, seasonOptions]);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      localStorage.setItem('currentSeason', currentSeason);
      window.location.reload(); // Reload the page when the season changes
    }
  }, [currentSeason]);

  return (
    <SeasonContext.Provider value={{ currentSeason, seasonOptions, setCurrentSeason, currentSeasonTime }}>
      {children}
    </SeasonContext.Provider>
  );
};
