import React, { useMemo, useState } from 'react';
import { championPhotos, championData } from '../data/ChampionsIcon';
import roleIcon from '../data/Role';
import { TeamIcons } from '../data/TeamIcons';
import Pagination from 'react-bootstrap/Pagination';


const AlternateDraftVersionComponent = ({ matchData, selectedFilterChampion , filterChampion, filterTeam}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const matchesGroupedByDate = useMemo(() => {
      const grouped = {};
      matchData.forEach(match => {
        const date = new Date(match.date).toISOString().split('T')[0]; // Extracting only the YYYY-MM-DD part
        if (!grouped[date]) grouped[date] = [];
        grouped[date].push(match);
      });
      return Object.entries(grouped).sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA));
    }, [matchData]);

    const paginatedDates = useMemo(() => {
      const dates = matchesGroupedByDate.map(([date]) => date);
      const startIndex = (currentPage - 1) * 7;
      return dates.slice(startIndex, startIndex + 7);
    }, [matchesGroupedByDate, currentPage]);

    const totalPages = Math.ceil(matchesGroupedByDate.length / 7);
    const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));


    const blueChampionsOrder = [
        "BB1",
        "BB2",
        "BB3",
        "BP1",
        "BP2",
        "BP3",
        "BB4",
        "BB5",
        "BP4",
        "BP5",
      ];
    const redChampionsOrder = [
        "RB1",
        "RB2",
        "RB3",
        "RP1",
        "RP2",
        "RP3",
        "RB4",
        "RB5",
        "RP4",
        "RP5",
      ];

      const handleMatchClick = (teamName) => {
        window.location.href = `/match/${teamName}`;
      };

      const renderPaginationItems = () => {
        const paginationItems = [];
        const ellipsis = <Pagination.Ellipsis />;

        paginationItems.push(
          <Pagination.First key="first" onClick={() => handlePageChange(1)} />,
          <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
        );

        if (totalPages <= 5) {
          // If total pages are less than or equal to 5, display all pages
          for (let i = 1; i <= totalPages; i++) {
            paginationItems.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }
        } else {
          // Display the first 2 pages and the last 2 pages
          for (let i = 1; i <= 2; i++) {
            paginationItems.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }

          // Display the current page and the last page, and ellipsis in between if needed
          if (currentPage - 2 > 2) {
            paginationItems.push(ellipsis);
          }
          for (let i = Math.max(currentPage - 2, 3); i <= Math.min(currentPage + 2, totalPages - 2); i++) {
            paginationItems.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }
          if (currentPage + 2 < totalPages - 1) {
            paginationItems.push(ellipsis);
          }

          for (let i = totalPages - 1; i <= totalPages; i++) {
            paginationItems.push(
              <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
                {i}
              </Pagination.Item>
            );
          }
        }

        // Always display the next page and last page if needed
        paginationItems.push(
          <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />,
          <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
        );

        return paginationItems;
      };

      const handlePageChange = (pageNumber) => {
        if (pageNumber !== currentPage && pageNumber >= 1 && pageNumber <= totalPages) {
          setCurrentPage(pageNumber);
        }
      };

  return (
    <div style={{width: '100%'}}>
      {paginatedDates.map(date => (
        <div key={date}>
          <h3 style={{color: 'white'}}>{date}</h3>
          <div style={{display: 'flex', flexWrap: 'wrap', gap:"10px"}}>
            {matchesGroupedByDate
              .find(([groupDate]) => groupDate === date)[1]
              .sort((a, b) => new Date(a.date)-  new Date(b.date) )
              .map((match, index) => (
                <div key={index} style={{width: "18%", padding: "5px"}}>
                <table>
                    <thead style={{ textAlign: 'center', backgroundColor: 'white' }}>
                    <tr>
                        <th className="default-cell-img" style={{backgroundColor: match.winner === 100 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 100 ? 1 : 0}</th>
                        <th className="teamName" style={{ backgroundColor: filterTeam.includes(match.team1) ? "yellow" :  "rgb(217, 217, 217)" }} > <img src={TeamIcons[match.team1]} alt='ni ma ikonki' className="small-image"/>{match.team1}</th>
                        <th className='default-doublecell-img' onClick={() => handleMatchClick(match.gameId)} colSpan={2}>{match.patch}</th>
                        <th className="teamName"  style={{ backgroundColor: filterTeam.includes(match.team2) ? "yellow" :  "rgb(217, 217, 217)" }}><img src={TeamIcons[match.team2]} alt='ni ma ikonki' className="small-image"/>{match.team2}</th>
                        <th className="default-cell-img" style={{backgroundColor: match.winner === 200 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{match.winner === 200 ? 1 : 0}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[...Array(10)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                        <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell-img" : "default-cell-img"}>
                            <img
                            src={championPhotos[championData[match[blueChampionsOrder[rowIndex]]]]}
                            alt={""}
                            className="small-image"
                            />
                        </td>
                        <td style={{borderBottom: rowIndex === 3 ? '2px solid black' : 'none'}} colSpan={rowIndex === 0 || rowIndex === 1 || rowIndex === 2 || rowIndex === 6 || rowIndex === 7 ? 2 : 1} className={(filterChampion.includes(match[blueChampionsOrder[rowIndex]]) || selectedFilterChampion.some(champ => champ && champ.value && Number(champ.value) === match[blueChampionsOrder[rowIndex]]) ? "yellow-cell" : (rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell" : "default-cell"))}>
                            {championData[match[blueChampionsOrder[rowIndex]]]}
                        </td>
                        {rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? (
                            <>
                            {rowIndex === 8 || rowIndex === 9 ? (
                            <>
                            <td className={"blue-cell-img"}>
                                <img src={roleIcon[match['B'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                            </td>
                            <td className={"red-cell-img"}>
                                <img src={roleIcon[match['R'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                            </td>
                            </>
                            ):(
                            <>
                                <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "blue-cell-img" : "default-cell-img"}>
                                <img src={roleIcon[match['B'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                                </td>
                                <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "red-cell-img" : "default-cell-img"}>
                                <img src={roleIcon[match['R'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                                </td>
                            </>
                            )
                        }
                            <td colSpan={1} style={{ borderBottom: rowIndex === 4 || rowIndex === 8 ? '2px solid black' : 'none' }}  className={ (filterChampion.includes(match[redChampionsOrder[rowIndex]]) || selectedFilterChampion.some(champ => champ && champ.value && Number(champ.value) === match[redChampionsOrder[rowIndex]]) ? "yellow-cell" : rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell")}>
                            {championData[match[redChampionsOrder[rowIndex]]]}
                            </td>
                            </>
                        ):(
                            <>
                            <td  colSpan={2} className={ (filterChampion.includes(match[redChampionsOrder[rowIndex]]) || selectedFilterChampion.some(champ => champ && champ.value && Number(champ.value) === match[redChampionsOrder[rowIndex]]) ? "yellow-cell" : rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell")}>
                            {championData[match[redChampionsOrder[rowIndex]]]}
                            </td>
                            </>
                        )}
                        <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell-img" : "default-cell-img"}>
                            <img
                            src={championPhotos[championData[match[redChampionsOrder[rowIndex]]]]}
                            alt={""}
                            className="small-image"
                            />
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
                    ))}
                </div>
                </div>
            ))}
      <div className="main-pagination" style={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination>{renderPaginationItems()}</Pagination>
      </div>
    </div>
  );
};

export default AlternateDraftVersionComponent;




