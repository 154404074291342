import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import roleIcon from './data/Role';
import axiosInstance from './backend/axiosInstance';
import 'react-pivottable/pivottable.css';
import { useSeason } from './SeasonContext';
import CustomSelect from './base/CustomSelect';
import SoloqPageComponent from './SoloqPage';
import rankIconDictionary from './data/RankIcons';
import iconDictionary from './data/Icons';
import Flag from 'react-world-flags';
import { getRedGreenCellno0 } from './base/colorUtils';
import WhiteSoloqPageComponent from './WhiteSoloqPage';

const TeamSoloqComponent = () => {
  const { currentSeasonTime } = useSeason();

  const { teamName } = useParams();
  document.title = "SoloQ " + teamName;

  const [playerData, setPlayerData] = useState([]);
  const [teamMatesData, setTeamMatesData] = useState(null);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);
  const [filteredPlayerChampionData, setFilteredPlayerChampionData] = useState([]);
  const [playersAccounts, setPlayersAccounts] = useState([]);
  const [startDate, setStartDate] = useState(new Date('2024-09-25'));
  const [endDate, setEndDate] = useState(new Date());

  const [showWhiteOPGG, setShowWhiteOPGG] = useState(false);

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());


    const fetchTeamMates = axiosInstance.get(`teammates/?name=${teamName}&start=${start}&end=${end}`);

    Promise.all([fetchTeamMates])
      .then(([teamMatesResponse]) => {
        setTeamMatesData(teamMatesResponse.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [teamName, currentSeasonTime]);

  const getPlayerNameWithoutTeamTag = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ').toLowerCase(); // Remove the team tag
  };

  useEffect(() => {
    if (teamMatesData) {
      const fetchAllAccounts = async () => {
        try {
          const promises = teamMatesData.map((teammate) =>
            axiosInstance.get(`accounts/?playername=${getPlayerNameWithoutTeamTag(teammate.summonerName)}`)
          );

          const results = await Promise.allSettled(promises);

          const successfulResults = results
            .filter((result) => result.status === 'fulfilled')
            .map((result) => result.value.data);

          setPlayersAccounts(successfulResults.flat());

          console.log('Fetched accounts:', successfulResults.flat());  // Log the successful data

          results
            .filter((result) => result.status === 'rejected')
            .forEach((result, index) => {
              console.error(`Error fetching account for player ${teamMatesData[index].summonerName}:`, result.reason);
            });
        } catch (error) {
          console.error('Error fetching accounts:', error);
        }
      };

      fetchAllAccounts();
    }
  }, [teamMatesData]);



  useEffect(() => {
    const filteredPlayersPick = playerData && playerData.filter((player) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
      return isPatchGood;
    });
    setFilteredPlayerChampionData(filteredPlayersPick || []);
  }, [playerData, filterPatch]);

  const generateMultiOpggUrl = () => {
    const eligiblePlayers = playersAccounts
      .filter(account => ["CHALLENGER", "GRANDMASTER", "MASTER"].includes(account.tier))
      .map(account => account.lastSummonerName.replace(/#/g, '%23').replace(/\s+/g, '+'));

    const baseUrl = "https://www.op.gg/multisearch/euw?summoners=";
    return baseUrl + eligiblePlayers.join('%2C');
  };

  const handlePatchChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

return (
  <div>
      <div style={{ padding: "30px", color: 'white'}}>
        <div style={{border: "2px solid rgb(0, 242, 255)"}}>
          <div style={{backgroundColor: "gray", display: 'flex', justifyContent: 'space-between', borderBottom: '4px solid white', }} >
            <div style={{width: "5%", paddingLeft: "10px"}}></div>
            <div style={{width: "17%", paddingLeft: "10px"}}>Name</div>
            <div style={{width: "23%"}}>Account</div>
            <div style={{width: "10%", textAlign: 'center'}}>Role</div>
            <div style={{width: "25%", textAlign: 'center'}}>Rank</div>
            <div style={{width: "10%", textAlign: 'center'}}>Games</div>
            <div style={{width: "10%", textAlign: 'center'}}></div>
          </div>
          {playersAccounts.filter((account) => account.tier !== "UNRANKED").map((account, index) => (
            <div key={account.id} style={{backgroundColor:  'gray', display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',    padding: '5px 0'}}>
              <div style={{width: "5%", paddingLeft: "10px"}}>{index + 1}.</div>
              <div style={{width: "2%"}}>
                <Flag code={account.country} alt={account.country} style={{width: '30px', height: '30px'}} />
              </div>
              <div style={{width: "15%", paddingLeft: "10px"}}>{account.name}</div>
              <div style={{width: "23%", display: 'flex', justifyContent: "space-between"}}>
                <div>{account.lastSummonerName}</div>
                <div>
                  <button
                    key={account.puuid}
                    style={{width: "25px", height: "25px"}}
                    onClick={() => window.open(`https://na.op.gg/summoners/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
                    className="account-button"
                  >
                    <img style={{width: "25px", height: "25px"}} src={iconDictionary["opgg"]} alt="opgg icon"/>
                  </button>
                  <button
                    key={account.summoner_id}
                    style={{width: "25px", height: "25px"}}
                    onClick={() => window.open(`https://www.deeplol.gg/summoner/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
                    className="account-button"
                  >
                    <img style={{width: "25px", height: "25px"}} src={iconDictionary["deeplol"]} alt="deeplol icon"/>
                  </button>
                </div>
              </div>
              <div style={{width: "10%", paddingLeft: "10px", justifyContent: 'center', display: "flex"}}>
                <img src={roleIcon[account.role]} style={{width: "30px"}} alt="role icon"/>
              </div>
              <div style={{width: "25%", display:'flex', justifyContent: 'center'}}>
                <img style={{width: "30px"}} src={rankIconDictionary[account.tier]} alt='rank icon'/> {account.tier} {["MASTER", "CHALLENGER", "GRANDMASTER"].includes(account.tier) ? "" : account.ranks} {account.leaguePoints} LP
              </div>
              <div style={{width: "10%", display:'flex', justifyContent: 'center'}}>
                {account.wins} / {account.losses}
                <div style={{color: getRedGreenCellno0(account.wins/(account.wins+account.losses)*100, 0, 100)}}>
                  &nbsp;{(account.wins/(account.wins+account.losses)*100).toFixed(0)}%
                </div>
              </div>
              <div style={{width: "10%", textAlign: 'center', justifyContent: 'space-around', display: 'flex'}}>
            </div>
            </div>
          ))}
        </div>
      </div>

      <div className='filters'>
        Starting Date

        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        />
        Ending Date
        <input
          type="date"
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
        Multi
        <button onClick={() => window.open(generateMultiOpggUrl(), '_blank')} className="account-button">
          <img style={{width: "25px", height: "25px"}} src={iconDictionary["opgg"]} alt="opgg icon"/>
        </button>
        White
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
              type="checkbox"
              className="custom-checkbox"
              id="favorites-only-checkbox"
              checked={showWhiteOPGG}
              onChange={(event) => setShowWhiteOPGG(event.target.checked)}
            />
        </div>

      </div>
      {!showWhiteOPGG ? (
        <>
        {teamMatesData  && (
          <div className="teammates-container">
            <div style={{display: 'flex'}}  >
              {teamMatesData.map(teammate => (
                <SoloqPageComponent
                  key={teammate.summonerName}
                  name={teammate.summonerName}
                  filterPatch={filterPatch}
                  startDate={startDate}
                  endDate={endDate}
                />
              ))}
            </div>
          </div>
        )}
        </>
      ):(
        <>
        {teamMatesData  && (
          <div className="teammates-container">
            <div style={{display: 'flex'}}  >
              {teamMatesData.map(teammate => (
                <WhiteSoloqPageComponent
                  key={teammate.summonerName}
                  name={teammate.summonerName}
                  filterPatch={filterPatch}
                  startDate={startDate}
                  endDate={endDate}
                />
              ))}
            </div>
          </div>
        )}
        </>
      )
      }
      </div>
          );
        };

export default TeamSoloqComponent;
