import React, { useMemo, useState } from 'react';
import { championPhotos, championData } from './data/ChampionsIcon';
import roleIcon from './data/Role';

const ChampionPool2Component = ({ filteredMatches = [], filteredTeam, filteredSide }) => {
  const roleMap = {
    0: 'Top',
    1: 'Jungle',
    2: 'Mid',
    3: 'ADC',
    4: 'Support',
  };

  const roleData = useMemo(() => {
    const roles = Object.keys(roleMap).reduce((acc, key) => {
      acc[key] = {};
      return acc;
    }, {});

    filteredMatches.forEach((match) => {
      const selectedTeam = Array.isArray(filteredTeam) ? filteredTeam[0] : filteredTeam;
      const selectedSide = Array.isArray(filteredSide) ? filteredSide[0] : filteredSide;

      let applicableSides = [];
      if (selectedTeam) {
        if (match.team1 === selectedTeam && (!selectedSide || selectedSide === 'team1' || selectedSide === 'any')) {
          applicableSides.push('B'); // Blue side
        }
        if (match.team2 === selectedTeam && (!selectedSide || selectedSide === 'team2' || selectedSide === 'any')) {
          applicableSides.push('R'); // Red side
        }
      } else {
        applicableSides = ['B', 'R']; // Include both sides if no team is selected
      }

      applicableSides.forEach((sidePrefix) => {
        const isWinningSide = (sidePrefix === 'B' && match.winner === 100) || (sidePrefix === 'R' && match.winner === 200);

        for (let i = 1; i <= 5; i++) {
          const roleIndex = match[`${sidePrefix}${i}R`];
          const championId = match[`${sidePrefix}P${i}`];

          if (!roles[roleIndex][championId]) {
            roles[roleIndex][championId] = { count: 0, wins: 0 };
          }
          roles[roleIndex][championId].count++;
          if (isWinningSide) {
            roles[roleIndex][championId].wins++;
          }
        }
      });
    });

    return { roles };
  }, [filteredMatches, filteredTeam, filteredSide]);

  const renderTable = (data, role) => (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <table
        style={{
          width: '100%',
          textAlign: 'center',
          borderCollapse: 'collapse',
          marginBottom: '20px',
          color: 'white',
        }}
      >
        <thead>
          <tr>
            <th>Champion</th>
            <th>Count</th>
            <th>Win Rate</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data)
            .sort((a, b) => b[1].count - a[1].count)
            .map(([championId, stats]) => {
              const { count, wins } = stats;
              const winRate = ((wins / count) * 100).toFixed(2); // Calculate win rate
              const championName = championData[championId] || 'Unknown'; // Handle invalid champion names
              return (
                <tr key={championId} style={{ cursor: 'pointer' }}>
                  <td
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <img
                      src={championPhotos[championName] || 'fallback-image-url'}
                      alt={championName}
                      style={{ width: '20px', marginRight: '5px' }}
                    />
                    {championName}
                  </td>
                  <td>{count}</td>
                  <td>{winRate}%</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
  return (
    <div style={{ color: 'white' }}>
      <div style={{ width: '100%', display: 'flex' }}>
        {Object.keys(roleMap).map((roleIndex) => {
          const roleName = roleMap[roleIndex];
          return (
            <div key={roleIndex} style={{ width: '20%', textAlign: 'center' }}>
              <h2>
                <img
                  src={roleIcon[roleIndex]}
                  alt={roleName}
                  style={{ width: '30px', marginRight: '10px', verticalAlign: 'middle' }}
                />
                {roleName}
              </h2>
              <div style={{ width: '100%', display: 'flex' }}>
                {renderTable(roleData.roles[roleIndex], roleIndex)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChampionPool2Component;
