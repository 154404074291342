import React, { useEffect, useState } from 'react';
import { championPhotos } from './data/ChampionsIcon'; // Assuming this is a mapping of champion names to their respective images
import './SoloqPageComponent.css'; // Import the CSS file
import iconDictionary from './data/Icons';
import axiosInstance from './backend/axiosInstance';
import { getRedGreenCellno0 } from './base/colorUtils';

const SoloqPageComponent = ({ name, filterPatch, startDate, endDate }) => {
  const [allGameData, setAllGameData] = useState([]); // Store all data
  const [averageStatsByChampion, setAverageStatsByChampion] = useState({});
  const [playerAccounts, setPlayerAccounts] = useState([]);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false); // New state for form visibility

  const getPlayerNameWithoutTeamTagLower = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ').toLowerCase(); // Remove the team tag
  };

  const getPlayerNameWithoutTeamTag = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' '); // Remove the team tag
  };

  const [newAccount, setNewAccount] = useState({ name: getPlayerNameWithoutTeamTagLower(name), lastSummonerName: '', opgg: 'euw', region: 'europe' });


  useEffect(() => {
    const playerName = getPlayerNameWithoutTeamTagLower(name);

    axiosInstance
      .get(`soloQ/?playername=${playerName}`)
      .then((response) => {
        const data = response.data.filter((game) =>
          filterPatch.length === 0 || filterPatch.includes(game.patch)
        );
        setAllGameData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axiosInstance
      .get(`accounts/?playername=${playerName}`)
      .then((response) => {
        setPlayerAccounts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching accounts:', error);
      });
  }, [name, filterPatch]);

  useEffect(() => {
    const filteredData = allGameData.filter((game) => {
      const gameDate = new Date(game.date);
      return gameDate >= startDate && gameDate <= endDate;
    });

    if (filteredData.length > 0) {
      const statsByChampion = filteredData.reduce((acc, game) => {
        if (!acc[game.champion_name]) {
          acc[game.champion_name] = {
            games: 0,
            kills: 0,
            deaths: 0,
            assists: 0,
            cs: 0,
            dmg: 0,
            win: 0,
            duration: 0,
          };
        }
        acc[game.champion_name].games += 1;
        acc[game.champion_name].kills += game.kills;
        acc[game.champion_name].deaths += game.deaths;
        acc[game.champion_name].assists += game.assists;
        acc[game.champion_name].cs += game.cs;
        acc[game.champion_name].dmg += game.dmg;
        acc[game.champion_name].win += game.win ? 1 : 0;
        acc[game.champion_name].duration += game.duration;
        return acc;
      }, {});

      const averagesByChampion = Object.keys(statsByChampion).reduce((acc, champion) => {
        const totalStats = statsByChampion[champion];
        acc[champion] = {
          games: totalStats.games,
          kills: (totalStats.kills / totalStats.games).toFixed(1),
          deaths: (totalStats.deaths / totalStats.games).toFixed(1),
          assists: (totalStats.assists / totalStats.games).toFixed(1),
          cs: (totalStats.cs / totalStats.games).toFixed(1),
          csPerMinute: (totalStats.cs / (totalStats.duration / 60)).toFixed(1),
          dmg: (totalStats.dmg / totalStats.games).toFixed(2),
          winRate: ((totalStats.win / totalStats.games) * 100).toFixed(0),
        };
        return acc;
      }, {});

      const sortedAveragesByChampion = Object.fromEntries(
        Object.entries(averagesByChampion).sort(([, a], [, b]) => b.games - a.games)
      );

      setAverageStatsByChampion(sortedAveragesByChampion);
    } else {
      setAverageStatsByChampion({});
    }
  }, [allGameData, startDate, endDate]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAccount(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddAccount = () => {
    const regionMap = {
      "jp": "asia",
      "kr": "asia",
      "oce": "sea",
      "euw": "europe",
    };

    const accountToAdd = {
      ...newAccount,
      region: regionMap[newAccount.opgg],
    };

    if (accountToAdd.lastSummonerName) {
      axiosInstance.post('add_account/', accountToAdd)
        .then(response => {
          alert('Account added successfully');
        })
        .catch(error => {
          console.error('Error adding account:', error);
        });
    } else {
      alert('Summoner Name cannot be empty');
    }
  };


  return (
    <div className="soloq-page">
      <div style={{ width: "100%", display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3>{getPlayerNameWithoutTeamTag(name)}</h3>
          <div>
          {playerAccounts.length > 0 ? (
            playerAccounts.map(account => (
              <button
              key={account.id}
              style={{width: "30px", height: "30px"}}
              onClick={() => window.open(`https://na.op.gg/summoners/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
              className="account-button"
              >
                <img style={{width: "30px", height: "30px"}} src={iconDictionary["opgg"]} alt="opgg icon"/>
              </button>
            ))
          ) : (
            <p>No accounts found for this player.</p>
          )}
          </div>
        </div>
        <div style={{ fontSize: "10px" }}>
          {playerAccounts.length > 0 ? (
            playerAccounts.map(account => (
              <React.Fragment key={account.id}>
                {account.lastSummonerName + " "}
              </React.Fragment>
            ))
          ) : (
            <p>No accounts found for this player.</p>
          )}
        </div>
        <div style={{ width: "100%", display: 'flex'}}>
          <button onClick={() => setIsAddFormVisible(!isAddFormVisible)}>
            {isAddFormVisible ? '-' : '+'}
          </button>
        {isAddFormVisible && (
          <div>
            <input
              type="text"
              name="lastSummonerName"
              placeholder="Summoner Name with tag"
              value={newAccount.lastSummonerName}
              onChange={handleInputChange}
            />
            <select
              name="opgg"
              value={newAccount.opgg}
              onChange={handleInputChange}
            >
              <option value="euw">EUW</option>
              <option value="kr">KR</option>
              <option value="jp">JP</option>
              <option value="oce">OCE</option>
            </select>
            <button onClick={handleAddAccount}>Add Account</button>
          </div>
        )}
        </div>
        {Object.keys(averageStatsByChampion).length > 0 ? (
          <div className="average-stats-container">
            {Object.keys(averageStatsByChampion).map(champion => (
              <div key={champion} className="champion-stats" style={{backgroundColor: "#545454"}}>
                <img src={championPhotos[champion]} alt={champion} className="champion-photo" />
                <div className="champion-details">
                  <div className="champion-left">
                    <div className="champion-name">{champion}</div>
                    <div className="champion-cs">CS {averageStatsByChampion[champion].cs} ({averageStatsByChampion[champion].csPerMinute})</div>
                  </div>
                  <div className="champion-middle">
                    <div className="champion-kda">{averageStatsByChampion[champion].kills} / {averageStatsByChampion[champion].deaths} / {averageStatsByChampion[champion].assists}</div>
                    <div className="champion-kda">KDA: {((parseFloat(averageStatsByChampion[champion].kills) + parseFloat(averageStatsByChampion[champion].assists)) / parseFloat(averageStatsByChampion[champion].deaths)).toFixed(2)}:1</div>
                  </div>
                  <div className="champion-right">
                    <div className="champion-winrate" style={{ color: getRedGreenCellno0(averageStatsByChampion[champion].winRate, 0, 100) }}>
                      {averageStatsByChampion[champion].winRate}%
                    </div>
                    <div className="champion-games">{averageStatsByChampion[champion].games} G</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
};

export default SoloqPageComponent;
